unit LogInU;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs;

type
  TLoginForm = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  LoginForm: TLoginForm;

implementation

{$R *.dfm}

procedure TLoginForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'LoginForm';
    Width := 640;
    Height := 480;
    ElementFont := efCSS;
    ElementPosition := epRelative;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -12;
    Font.Name := 'Segoe UI';
    Font.Style := [];
    ParentFont := False;
  finally
  end;
end;

end.
