unit _WebAppU;

interface

uses System.Types, System.Classes,
     Web,
     JS,
     WEBLib.Utils,
     WEBLib.Forms,
     WEBLib.WebSocketClient,
     WEBLIB.Controls,
     WEBLib.IndexedDb,
     _ObjUnit,
     _OrmCore;

type _WebApp                        = class(_App)
                                      private
                                        fWebOnline   : Boolean;
                                        fDBReady     : Boolean;
                                        fWSReady     : Boolean;
                                        fDB          : TIndexedDb;
                                        fWS          : TSocketClient;
                                        fSequenceID  : Int64;
                                       procedure OnWSConnect     ( Sender : TObject );
                                       procedure OnWSDisconnect  ( Sender : TObject );
                                       procedure OnWSMessgage    ( Sender : TObject; AMessage: string);
//                                       procedure OnWSBinary      ( Sender : TObject; AData: TBytes);
                                       procedure OnData          ( Sender : TObject; Origin: string; SocketData: TJSObjectRecord);

                                       procedure OnIDBResult(success: Boolean; opCode: TIndexedDbOpCode; data, sequenceId: JSValue; errorName, errorMsg: String);
                                       procedure OpenDB;
                                       procedure GetSSLPassword(Sender: TObject; var APassword: string);

                                       procedure OpenWS;
                                       procedure ApplicationOnlineChange(Sender: TObject; AStatus: TOnlineStatus);
                                      public
                                       /// TODO : aEnsureIntegrity must be built into _Obj collection that can speak to WS to stay up to date
                                       ///        But we need a double-cache, app<->IdxDB<->Server<=>Peers
                                       SessionInfo : TWSSessionInfo;
//                                       AppData     : TAppData;
                                       constructor Create(AOwner: TComponent); override;
                                       destructor  Destroy; override;
                                       procedure CommandReceived ( aSource : TObject; aCmd : TWSMsgCmds; const aData : JSValue );virtual;

                                       procedure SendCommand   ( aCmd : TWSMsgCmds; const aMessage : String);
                                       procedure SendMessage   ( const aMessage : String );
                                       function  WaitRequest   ( const aURL     : TString):JSValue;
                                       function  WaitQuery     ( const aQuery   : TOrmQry):JSValue;

                                       function  OpenCollection( const aTableName          : String;                          aEnsureIntegrity : Boolean = False ) : JSValue;override;
                                       function  RetrieveList  ( const aTableName, aSelect: String; aWhere: _WhereFunc = nil; aEnsureIntegrity : Boolean = False ) : JSValue;override;
                                       function  RetrieveJson  ( const aTableName, aSelect: String; aWhere: _WhereFunc = nil; aEnsureIntegrity : Boolean = False ) : String;
                                       function  GetSequenceID ( aAdvance : Boolean = True ):Int64;
                                       function  NextSeq:Int64;
//                                       function  GetService<T:IInterface> ( const aName : String ) :T;

                                       function SanitizePhoneNumber( const PhoneNumber : String ) : String;override;

                                       [JSExport]
                                       function Login( const aAddress : String ; out TokenSentTo : String ) : Boolean;

                                       property IsDBReady : Boolean read fDBReady;
                                       property IsWSReady : Boolean read fWSReady;
                                      end;

var WebApp : _WebApp;

implementation

{ _WebApp }

constructor _WebApp.Create(AOwner: TComponent);
begin
 inherited;
 fSequenceID := 1;
 fDBReady    := False;
 fWSReady    := False;
 fDB         := TIndexedDb.Create;
// fDB.DatabaseName    := 'TrueWeb';
// fDB.ObjectStoreName := 'WebApp';
// fDB.KeyFieldName    := 'Key';
 fWS         := TSocketClient.Create;
 fWS         .Hostname  := 'api.true.co.za';
 fWS         .Port      := 8887;
 fWS         .UseSSL    := True;
// fWS         .OnGetSSLPassword:=GetSSLPassword;
 fWS.Active := True;

// OpenDB;
 Application.OnOnlineChange   :=  ApplicationOnlineChange;
end;

procedure _WebApp.ApplicationOnlineChange(Sender: TObject; AStatus: TOnlineStatus);
begin
 fWebOnline:=(AStatus=osOnline);
 // TODO : Log online changes
end;

destructor _WebApp.Destroy;
begin
 fWS.Free;
 fDB.Free;
 inherited;
end;

procedure _WebApp.OpenDB;
begin
 fDB.OnResult:=OnIDBResult;
// fDB.AddIndex(
 fDB.Open('TrueApp','Contacts','ID',False,GetSequenceID);
end;

procedure _WebApp.OpenWS;
begin
 fWS := TSocketClient.Create(Application);
 fWS.HostName             := 'api.true.co.za';
 fWS.Port                 := 8887;
 fWS.UseSSL               := True;
 fWS.OnConnect            := OnWSConnect;
 fWS.OnDisconnect         := OnWSDisconnect;
 fWS.OnMessageReceived    := OnWSMessgage;
// fWS.OnBinaryDataReceived := OnWSBinary;
 fWS.OnDataReceived       := OnData;
 fWS.Active               := True;
end;

procedure _WebApp.OnIDBResult(success: Boolean; opCode: TIndexedDbOpCode; data, sequenceId: JSValue; errorName, errorMsg: String);
begin
 if not success
    then begin
          Assert(False,errorName+':'+errorMsg);
         end;
 case opCode of
  opOpen              : begin
                         fDBReady := True;
                         if not fWS.Active
                            then begin
                                  OpenWS;
                                 end;


                        end;
  opAdd               : ;
  opPut               : ;
  opDelete            : ;
  opGet               : ;
  opGetAllKeys        : ;
  opGetAllObjs        : ;
  opGetAllIndexKeys   : ;
  opGetAllObjsByIndex : ;
 end;
end;

procedure _WebApp.OnWSConnect(Sender: TObject);
begin
 fWSReady := True;

 // Remember login from IdxDB?

// sessionInfo.DeviceID  :='1234567890';
// sessionInfo.SessionID :='1234567890';
// sessionInfo.UserID    :=0;
// sessionInfo.UserName  :='Unknown';
// sessionInfo.UserPhoto :=0;
// sessionInfo.UserEMail :='';
// sessionInfo.UserPhone :='';
end;

procedure _WebApp.OnWSDisconnect(Sender: TObject);
begin
 fWSReady := False;
end;

procedure _WebApp.OnData(Sender: TObject; Origin: string; SocketData: TJSObjectRecord);
begin
 Assert(False);
end;

procedure _WebApp.OnWSMessgage(Sender: TObject; AMessage: string);
var val    : JSValue;
    sMsg  : TServerMessage;
    cData : TCellContent;
    I, J  : Integer;
    tData : TTableContent;
    cmd   : UInt16;
begin
 val  := TJSJSON.parse(AMessage);//SocketData.jsobject.toString);
 sMsg := TServerMessage(val);
 case sMsg.MsgType of
  cmdUpdateCell        : begin
                          cData := TCellContent(sMsg.data);
//                          cData.
                             // grid.Cells[cData.col, cData.row] := cData.value;
                         end;
  cmdUpdateTable       : begin
                          tData := TTableContent(sMsg.data);
                          // grid.RowCount := tData.rows;
                          // grid.ColCount := tData.cols;
                          // grid.BeginUpdate;
                          for I := 0 to tData.rows - 1 do
                           begin
                            for J := 0 to tData.cols - 1 do
                             // grid.Cells[J, I] := tData.content[(I * 30) + J];
                           end;
                          // grid.EndUpdate;
                         end;
 else                    begin
                          // TODO : Log invalid message+IP+fingerprint to security module
                         end;
 end;
end;

//procedure _WebApp.OnWSBinary(Sender: TObject; AData: TBytes);
//begin
// Assert(False);
//end;
//
function _WebApp.GetSequenceID(aAdvance: Boolean): Int64;
begin
 Result:=fSequenceID;
 if aAdvance
    then Inc(fSequenceID);
end;

function _WebApp.NextSeq: Int64;
begin
 exit(GetSequenceID(True));
end;

function _WebApp.SanitizePhoneNumber(const PhoneNumber: String): String;
var JSResult: string;
begin
  asm
    var phoneNumber = PhoneNumber;

    // Remove all non-digit characters
    var cleanedNumber = phoneNumber.replace(/\D/g, '');

    // Check the length and add international code if necessary
    if (cleanedNumber.startsWith('27') && cleanedNumber.length === 11) {
      JSResult = '+' + cleanedNumber;
    } else if (cleanedNumber.length === 9 || cleanedNumber.length === 10) {
      JSResult = '+27' + cleanedNumber.slice(-9);
    } else {
      JSResult = ''; // Return empty string for invalid numbers
    }
  end;

  Result := JSResult;
end;

procedure _WebApp.SendCommand(aCmd: TWSMsgCmds; const aMessage: String);
var M : TServerMEssage;
begin

end;

procedure _WebApp.SendMessage(const aMessage: String);
begin
 if not IsWSReady
    then begin
          // TODO : Cache/Queue the message until online; depending on msg type
          exit;
         end;
 fWS.Send(aMessage);  // Send it to socket
end;

function _WebApp.WaitQuery(const aQuery: TOrmQry):JSValue;
var Q : TOrmQry;
    M : TServerMessage;
    V : TJSObject;

begin
 Q:=aQuery;

 Q.ticker:=GetSequenceID(True);
 V:=TJSObject(aQuery);
 SendMessage(V.ToString);  // Send it to socket
 // Wait for replay to come back

end;

function _WebApp.WaitRequest(const aURL: TString): JSValue;
begin

end;

procedure _WebApp.GetSSLPassword(Sender: TObject; var APassword: string);
begin
 APassword := '';
end;

function _WebApp.Login(const aAddress: String; out TokenSentTo: String): Boolean;
var IsPhone : Boolean;
    IsEmail : Boolean;
    JSV     : JSValue;
begin
 // Check valid type
 IsPhone:=true;//IsValidPhoneNo;
 IsEMail:=false;//IsValidEmailAddress;
 if not (IsPhone or IsEmail)
    then exit(False);
// JSV:=GetRequest('
end;

procedure _WebApp.CommandReceived(aSource: TObject; aCmd: TWSMsgCmds; const aData: JSValue);
begin
 case aCmd of
  cmdValidatePreLogin   : begin

                          end;
  cmdLogin              : begin

                          end;
  cmdSubmit             : begin

                          end;
  cmdUpdateCell         : begin

                          end;
 end;
end;

function _WebApp.OpenCollection(const aTableName: String; aEnsureIntegrity: Boolean): JSValue;
begin
 // TODO : Flag to see if it's in IndexedDB, open it so it can sync/update;   BOTH WAYS
// if not IsWSReady
//    then begin
//          Assert(False,'WS not open');
//         end;
// DataMod.
end;

function _WebApp.RetrieveJson( const aTableName, aSelect: String; aWhere: _WhereFunc = nil; aEnsureIntegrity: Boolean = False) : String;
var V : JSValue;
begin
 V      := RetrieveList(aTableName,aSelect,aWhere,aEnsureIntegrity);
// Result := TJSON.Stringify(V);
end;

function _WebApp.RetrieveList( const aTableName, aSelect: String; aWhere: _WhereFunc = nil; aEnsureIntegrity: Boolean = False) : JSValue;
var O    : _Col;
    fArr : TArray<_Field>;
    jArr : TJSArray;
    jObj : TJSObject;
    Idxs : TIntegerDynArray;
    I,J  : Integer;
    cCnt : Integer;
    VStr : String;
begin
 if not Collections.TryGetValue(aTableName,O)
    then begin
          // Get new synched collection from server
          console.log('Table not found '+aTableName);
          exit;
//          WebApp.
         end;

 fArr := O.ObjDef.GetFieldsCSV(aSelect);
 cCnt := Length(fArr);
 if not Assigned(aWhere)
    then begin
          SetLength( Idxs, O.RowCount);
          for I := 0 to Pred(O.RowCount) do
           Idxs[I]:=I;
         end
    else begin
          J:=0;
          SetLength( Idxs, O.RowCount);
          for I := 0 to Pred(O.RowCount) do
           begin
            if aWhere(O.Row[I])
               then begin
                     Idxs[J]:=I;
                     Inc(J);
                    end;
           end;
          SetLength(Idxs,J);
         end;

 // All rows
 jArr  := TJSArray(Result);
 for I:= 0 to High(Idxs) do
  begin
   for J:=0 to Pred(cCnt) do
    begin
     VStr:=O.CellStr[Idxs[I],J];
     jObj.Properties[fArr[J].Name]:=TJSString(VStr);
    end;
   jArr.Push(jObj);
  end;
 Result:=jArr;
end;

end.

//end;procedure TForm1.QueryWithCursor;
//begin
//  // Open the store with a cursor
//  WebIndexedDB.OpenCursor('myDatabase', 'myStore', 'fieldIndex', 'mySearchValue');
//end;
//
//procedure TForm1.WebIndexedDBIndexedDBResult(Sender: TObject;
//  Success: Boolean; OpCode: TIndexedDbOpCode; Data, SequenceId: JSValue;
//  ErrorName, ErrorMsg: String);
//begin
//  if Success then
//  begin
//    case OpCode of
//      idbocOpenCursor:
//        begin
//          // Cursor is open; process the data
//          ProcessCursor(Data);
//        end;
//
//      // Handle other operations if necessary
//    end;
//  end
//  else
//  begin
//    ShowMessage('Error: ' + errorMsg);
//  end;
//end;
//
//procedure TForm1.ProcessCursor(Data: JSValue);
//begin
//  // Handle each item returned by the cursor
//  var jsArray := TJSArray(Data);
//
//  // Apply your complex logic on each item
//  jsArray.forEach(
//    function(item: JSValue; index: Integer; arr: TJSArray): Boolean
//    begin
//      var jsObj := TJSObject(item);
//      // Example: Filter items and store in a list
//      if string(jsObj['status']) = 'active' then
//      begin
//        AddToResultList(jsObj);
//      end;
//      Result := True;
//    end
//  );
//end;
//

