unit SignInU;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, Vcl.StdCtrls, WEBLib.StdCtrls;

type
  TSignInForm = class(TForm)
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  SignInForm: TSignInForm;

implementation

{$R *.dfm}

procedure TSignInForm.LoadDFMValues;
begin
  inherited LoadDFMValues;


  try
    Name := 'SignInForm';
    Width := 640;
    Height := 480;
  finally
  end;
end;

end.