unit _OrmCore;

interface

{$REGION 'Uses'}
uses
  

System.SysUtils,
  System.Variants,
  System.Classes,
  System.Types,
  System.DateUtils,
  contNrs
//  fmx.Graphics,
//  fmx.Controls,
//  fmx.Forms,
//  fmx.Dialogs,
//  fmx.StdCtrls,
//  fmx.Imaging.Jpeg,
//  fmx.Imaging.PngImage,
//  fmx.Imaging.GIFImg


{$IFNDEF WEBLIB}
     ,
     mormot.core.base,
     mormot.core.buffers,
     mormot.core.collections,
     mormot.core.data,
     mormot.core.datetime,
     mormot.core.json,
     mormot.core.mustache,
     mormot.core.os,
     mormot.core.rtti,
     mormot.core.search,
     mormot.core.text,
     mormot.core.threads,
     mormot.core.unicode,
     mormot.core.variants,
     mormot.core.zip,
     mormot.orm.base,
     mormot.orm.core,
     mormot.orm.rest,
     mormot.orm.storage,
     mormot.orm.sqlite3,
     mormot.orm.server,
     mormot.crypt.core,
     mormot.rest.core,
     mormot.rest.server,
     mormot.rest.sqlite3,
     mormot.rest.http.server,
     mormot.soa.core,
     mormot.soa.codegen,
     mormot.soa.server,
     mormot.db.raw.sqlite3,
     mormot.db.raw.sqlite3.static,
     mormot.ui.gdiplus,
     mormot.core.interfaces;

type TString             = RawUTF8;
     TID                 = mormot.core.base.TID;
     _RawBlob            = type RawBlob;
{$ENDIF}
{$IFDEF WEBLIB}
   ,
   JS,
   Web,
   Graphics,
   WEBLib.Graphics;

type TString             = String;
     TID                 = UInt64;
     TIDDynArray         = Array of TID;
     _RawBlob            = type TString;
     TCreateTime         = TTimeStamp;
     TModTime            = TTimeStamp;

type IRestOrm            = interface
                            ['{BC6EC3F2-E4EF-42B7-9402-633942D410E8}']
                           end;

type TFillTable          = class
                            RowCount : Integer;
                           end;

type TOrm                = class(TPersistent)
                           private
                            fID      : TID;
                           public
                            FillTable : TFillTable;
                            constructor Create;virtual;
                            constructor CreateAndFillPrepare( aRest: IRestOrm; aSqlWhere : TString = ''; aOArams : array of const = []; aFieldsCsv : TString = '');virtual;abstract;
                            function    RowCount : Integer;virtual;abstract;
                            function    FillOne  : Boolean;virtual;abstract;

                            property    ID       : TID          read fID             write fID;
                           end;
type TOrmClass           = class of  TOrm;
     TOrmFts5Unicode61   = class(TOrm)
                           end;
{$ENDIF}
{$ENDREGION 'Uses'}

/// [TStringTag] is a special type where the field name determine the global lookup list
/// e.g. PhoneType      : TStringTag   ; ENUM + the global enum list name "PhoneType"
/// e.g. PhoneTypes     : TStringTagArr; SET
type TStringTag           = type TString;
     TStringTagArr        = Array of TStringTag;


type TOrmQry       = record
                      Table,
                      Select,                 // 'ID,Name,EMail';
                      Where   : String;       // 'Name=Yo and Age=?'
                      ticker  : Integer;      // SeqID
                     end;


implementation

{$IFDEF WEBLIB}
{ TOrm }
constructor TOrm.Create;
begin
 inherited Create;
// fFileRefs:=TFileReferenceList.Create;
end;
{$ENDIF}

end.
