program TrueApp;

uses
  Vcl.Forms,
  WEBLib.Forms,
  SignInU in 'FORMS\SignInU.pas'{*.html},
  _OrmCore in '..\TruePWA\WebsockServer\_OrmCore.pas',
  _ObjUnit in '..\TruePWA\WebsockServer\_ObjUnit.pas',
  LogInU in 'FORMS\LogInU.pas'{*.html},
  _WebAppU in '_WebAppU.pas',
  _ObjRegisters in '_ObjRegisters.pas',
  _WebRest in '_WebRest.pas',
  _WebDataMod in '_WebDataMod.pas';

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  WebApp:=_WebApp.Create(nil);
  Application.CreateForm(TSignInForm, SignInForm);
  Application.CreateForm(TLoginForm, LoginForm);
  Application.CreateFormDirect(TDataModule1, DataModule1);
  Application.Run;
end.
