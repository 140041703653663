unit _WebDataMod;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, Data.DB,
  WEBLib.IndexedDb;

type
  TDataModule1 = class(TDataModule)
    DBSha: TIndexedDbClientDataset;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  DataModule1: TDataModule1;

implementation

{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

procedure TDataModule1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  DBSha := TIndexedDbClientDataset.Create(Self);

  DBSha.BeforeLoadDFMValues;
  try
    Name := 'DataModule1';
    Height := 556;
    Width := 851;
    DBSha.SetParentComponent(Self);
    DBSha.Name := 'DBSha';
    DBSha.IDBDatabaseName := 'TrueApp';
    DBSha.IDBObjectStoreName := 'Sha';
    DBSha.IDBKeyFieldName := 'Key';
    DBSha.IDBAutoIncrement := True;
    DBSha.Left := 88;
    DBSha.Top := 40;
  finally
    DBSha.AfterLoadDFMValues;
  end;
end;

end.
