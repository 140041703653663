unit _ObjUnit;

interface

/// If we perfect the immortal 'live' Object that live in a single block of memory, e.g. on a MMF,
/// Then it can be converted to C++ and compiled as WebAsm to make our browser based software the most insanely fast node.
/// With RDMA and multipath, distributed storage, it can be
/// The ultimate solution for data performance, redundancy, availability, scalability.
/// Imagine copying a large object that is already bit-compacted and deduplicated direct onto network buffer,
/// then the browser receives and copy direct from network buffer(or RDMA).
/// In-memory data used by the browser is still bit-compacted and/or compressed and/or encrypted
/// Or it can be partially deflated/assembled(de-deduplicated) for e.g. browser rendering or calculations.
/// Web apps will be able to handle HUGE amounts of data at ImmortalObject's lightning speeds, while keeping disk and RAM usage and traffic low.

// Partial description of 4D event monitoring software, e.g possible locations of suspects, asteroids. Use similar math to metaballs/PovRay-Blobs


/// The defaults for a Class, and the master/server/disk copy of an Object, is similar in a way,
/// in that we care about the delta changes of fields. Fields might be :
///   Absolute    : E.g. name or phone number
///   Incremental : E.g. RowID or InvoiceNumber
///   Additive    : E.g. account balance
///   Partial     : E.g. Flags or list/array
{$REGION 'USES'}
uses System.Types, System.Classes, System.SysUtils,  System.StrUtils, System.Variants, System.TypInfo, System.Rtti, System.Generics.Defaults, System.Generics.Collections,
     Data.DB,
     Web,
     JS, _OrmCore
     //, HtmlRenderer, HtmlConst
{$IFDEF WEBLIB}
     ,WEBLib.Controls,
     WEBLib.Utils;

type TVariant         = TJSObject;
{$ENDIF}
{$IFNDEF WEBLIB}
     ;
type JSValue = TJSObject;
type TVariant         = type Variant;
{$ENDIF}
{$ENDREGION 'USES'}

{$REGION 'TABLE CONTENT'}

type  TWSMsgCmds           = (
                               cmdValidatePreLogin  ,
                               cmdLogin             ,
                               cmdSubmit            ,
                               cmdUpdateTable       ,
                               cmdUpdateCell
                             );
type TWSMsg            = record
                          cmd  : Integer;
                          rec  : JSValue;
                         end;
//type TCollectionIdx        = Integer;
type TServerMessage        = record
                              ticker    : Int64;
                              message   : string;
                              data      : JSValue;
                              function MsgType : TWSMsgCmds;
                             end;

//      TTableMessage        = record
//                              table      : Integer;
//                              tableTick  : UInt64;
//                              data       : JSValue;
//                             end;
//
      TCellContent         = record
                              table : Integer;
                              row   : Integer;
                              col   : Integer;
                              value : string;
                             end;

      TRowContent          = record
                              table    : Integer;
                              row      : Integer;
                              rowTick  : UInt64;
                              values   : TStringDynArray;
                             end;

      TTableContent        = record
                             public
                              table      : String;
                              tableTick  : UInt64;
                              rows       : Integer;
                              cols       : Integer;
                              content    : TStringDynArray;
                              function  GetCell(const aRow, aCol : Integer): String;
                              procedure SetCell(const aRow, aCol : Integer; const aValue: String);
                              property  Cell  [ const aRow, aCol : Integer] : String read GetCell write SetCell;
                             public
                              // Get Selected/Filtered/Sorted list
                              function GetSorted ( aSortColNo : Integer; aCols: TIntegerDynArray = []; aRows : TIntegerDynArray = [] ) : TStringDynArray;
                             end;
{$ENDREGION 'TABLE CONTENT'}

{$REGION 'WEBREQUEST FORM'}
/// Used in FormWebRequest
type TObjType   = ( cSample,
                    cContactBasic,
                    cWispClients
                  );
type TObjURLs   = record
                   Fields    : TStringDynArray;
                   DataURL   : String; // e.g. https://api.true.co.za/get
                   constructor Create ( const             aFields, aDataURL : String );
                   function IsOrm:Boolean;
                  end;
{$ENDREGION 'WEBREQUEST FORM'}


{$REGION 'META TYPES'}
type TValueDynArray   = array of TValue;
type TValueDynArrayArr= array of TValueDynArray;
type _ViewFlag                    = ( vfHoriz, vfVert, vfCompact, vfLabelTop, vfNoLabel, vfNoIcon );
     _ViewFlags                   = set of _ViewFlag;
     _StreamKind                  = ( skBinary, skCSV, skJson, skHTML );
     _StreamFlag                  = ( sfHTML  , sfCSS, sfJS, sfMustache, sfHTMLTemplate, sfJson, sfData, sfDifferential, sfCompressed, sfEncrypted, sfToForm, sfFromForm, sfToDB, sfFromDB, sfToServer, sfFromServer );
     _StreamKinds                 = set of _StreamKind;
     _StreamFlags                 = set of _StreamFlag;

type _ColumnType      = (ctNone, ctInteger, ctDouble, ctCurrency, ctTimeLog, ctDateTime, ctDate, ctTime, ctBoolean, ctEnums, ctSet, ctString, ctMultiline,
                         ctName, ctPassword, ctPhone, ctEmail, ctAddress, ctCity, ctCountry, ctPostalCode, ctUrl, ctFileName, ctIpv4, ctIpv6,
                         ctTID , ctFileExt, ctMimeType, ctBlobStr, ctFile, ctCel, ctFax, ctIDnum, ctRegNo, ctVatNo, ctGPS,
                         ct_QUERY );
type _WidgetType      = (wtNone, wtBreak, wtForm, wtPanel, wtListBox, wtEditText, wtMemo, wtComboBox, wtCheckBox, wtCheckGroup, wtRadioGroup, wtDateTimeEdit, wtButton, wtFile, wtImage, wtAvatar );
type _TextInputType   = (ttNone, ttText, ttMultiline, ttSpinEdit, ttInteger, ttDouble, ttCurrency, ttPhone, ttDateTime, ttEmailAddress, ttURL, ttVisiblePassword, ttName, ttStreetAddress);
type _HTMLFieldType   = (dtNone, dtText, dtPassword, dtEmail, dtTel, dtNumber, dtDate, dtTime, dtDateTime, dtDateTimeLocal, dtMonth, dtWeek, dtURL, dtSearch, dtColor, dtCheckbox, dtRadio, dtFile, dtRange, dthidden, dtbutton, dtimage, dtreset, dtsubmit);
type _TextInputAction = (taNone, taUnspecified, taDone, taGo, taSearch, taSend, taNext, taPrevious, taContinue, taJoin, taRoute, taEmergencyCall, taNewline);
type _BracketType     = (btNone, btRound, btCurly, btSquare, btAngle);
type _MetaMode        = (mmNone, mmEnum, mmSet, mmArray, mmMap);
type _MetaTypeFlag    = (flgBaseType, flgValid, flgPrinted, flgUsed);
type _MetaTypeFlags   = set of _MetaTypeFlag;
type _MetaSIUnit      = ( siNone,                                //Quantity	SI Unit	Common Subunits or Types
                          siLength,                              //Length	            meter       (m )	mm, cm, km
                          siVolume,                              //Mass	              kilogram    (kg)	g, mg, tonne
                          siMass,                                //Time	              second      (s )	ms, min, hour
                          siTime,                                //Speed	                           m/s	km/h, mph
                          siSpeed,                               //Volume	            cubic meter (m³)	liter (L), mL, cm³
                          siPressure,                            //Force	              newton      (N )	dyne (CGS system)
                          siTemperature,                         //Energy	            joule       (J )	kJ, MJ
                          siCharge,                              //Power	              watt        (W )	kW, MW
                          siCurrent,                             //Pressure	          pascal      (Pa)	kPa, bar, mmHg
                          siVolts,                               //Electric Current	  ampere      (A )	mA, µA
                          siFrequency,                           //Temperature	        kelvin      (K )	°C, °F
                          siEnergy,                              //Charge	            coulomb     (C )	mC, µC
                          siForce,                               //Frequency	          hertz       (Hz)	kHz, MHz
                          siPower,
                          siMole,
                          siCandela);

type _AutocompleteType = (
                           ac_OFF,
                           ac_ON,
                           acName,
                           acHonorificPrefix,
                           acGivenName,
                           acAdditionalName,
                           acFamilyName,
                           acHonorificSuffix,
                           acNickname,
                           acEmail,
                           acUsername,
                           acNewPassword,
                           acCurrentPassword,
                           acOrganizationTitle,
                           acOrganization,
                           acTel,
                           acTelCountryCode,
                           acTelNational,
                           acTelAreaCode,
                           acTelLocal,
                           acTelExtension,
                           acImpp,
                           acStreetAddress,
                           acAddressLine1,
                           acAddressLine2,
                           acAddressLine3,
                           acAddressLevel1,
                           acAddressLevel2,
                           acAddressLevel3,
                           acAddressLevel4,
                           acCountry,
                           acCountryName,
                           acCountryCode,
                           acPostalCode,
                           acCcName,
                           acCcGivenName,
                           acCcFamilyName,
                           acCcNumber,
                           acCcExp,
                           acCcExpMonth,
                           acCcExpYear,
                           acCcCsc,
                           acCcType,
                           acTransactionAmount,
                           acTransactionCurrency,
                           acLanguage,
                           acBday,
                           acBdayDay,
                           acBdayMonth,
                           acBdayYear,
                           acSex,
                           acUrl,
                           acPhoto
                         );

const AutocompleteStrings: array[_AutocompleteType] of string = (
                           'off',
                           'on',
                           'name',
                           'honorific-prefix',
                           'given-name',
                           'additional-name',
                           'family-name',
                           'honorific-suffix',
                           'nickname',
                           'email',
                           'username',
                           'new-password',
                           'current-password',
                           'organization-title',
                           'organization',
                           'tel',
                           'tel-country-code',
                           'tel-national',
                           'tel-area-code',
                           'tel-local',
                           'tel-extension',
                           'impp',
                           'street-address',
                           'address-line1',
                           'address-line2',
                           'address-line3',
                           'address-level1',
                           'address-level2',
                           'address-level3',
                           'address-level4',
                           'country',
                           'country-name',
                           'country-code',
                           'postal-code',
                           'cc-name',
                           'cc-given-name',
                           'cc-family-name',
                           'cc-number',
                           'cc-exp',
                           'cc-exp-month',
                           'cc-exp-year',
                           'cc-csc',
                           'cc-type',
                           'transaction-amount',
                           'transaction-currency',
                           'language',
                           'bday',
                           'bday-day',
                           'bday-month',
                           'bday-year',
                           'sex',
                           'url',
                           'photo'
                         );

{$REGION 'Typed Types'}
type _Multiline          = type TString;
     _CSV                = type TString;
     _EnumCSV            = type _CSV;
     _SetCSV             = type _EnumCSV;
     _FileContent        = _RawBlob;
     _GUID               = type TString;
     _CODE               = type TString;

     _Name               = type TString;
     _HonorificPrefix    = type TString;
     _GivenName          = type TString;
     _AdditionalName     = type TString;
     _FamilyName         = type TString;
     _HonorificSuffix    = type TString;
     _Nickname           = type TString;
     _Email              = type TString;
     _Username           = type TString;
     _NewPassword        = type TString;
     _CurrentPassword    = type TString;
     _OrganizationTitle  = type TString;
     _Organization       = type TString;
     _Tel                = type TString;
     _TelCountryCode     = type TString;
     _TelNational        = type TString;
     _TelAreaCode        = type TString;
     _TelLocal           = type TString;
     _TelExtension       = type TString;
     _Impp               = type TString;
     _StreetAddress      = type _Multiline;
     _AddressLine1       = type TString;
     _AddressLine2       = type TString;
     _AddressLine3       = type TString;
     _AddressLevel1      = type TString;
     _AddressLevel2      = type TString;
     _AddressLevel3      = type TString;
     _AddressLevel4      = type TString;
     _Country            = type TString;
     _CountryName        = type TString;
     _CountryCode        = type TString;
     _PostalCode         = type TString;
     _CcName             = type TString;
     _CcGivenName        = type TString;
     _CcFamilyName       = type TString;
     _CcNumber           = type TString;
     _CcExp              = type TString;
     _CcExpMonth         = type TString;
     _CcExpYear          = type TString;
     _CcCsc              = type TString;
     _CcType             = type TString;
     _TransactionAmount  = type TString;
     _TransactionCurrency= type TString;
     _Language           = type TString;
     _Bday               = type TString;
     _BdayDay            = type TString;
     _BdayMonth          = type TString;
     _BdayYear           = type TString;
     _Sex                = type TString;
     _Url                = type TString;
     _Photo              = type TString;

     _Avatar             = type _Photo;
     _City               = type TString;
     _FileName           = type _Url;
     _MimeType           = type TString;
     _FileType           = type TString;
     _IdNum              = type _GUID;
     _RegNo              = type _GUID;
     _VatNo              = type _GUID;

//type _Enum             = array of TString;
//     _Set              = array of TString;
//     _MetaTag          = array of TString;
//     _Notes            = array of TString;

{$ENDREGION}

type _MetaType                      = class
                                      public
                                       Name              : String;
                                       DisplayName       : String;
                                       DisplayPlural     : String;
                                       DisplayWidth      : Integer;
                                       Description       : String;
                                       Icon              : String;
                                       IconSelected      : String;
                                       DisplayPerc       : Integer;
                                       SortStrSize       : Integer;
                                       SearchPrior       : UInt8;
                                       SortNumber        : UInt8;
                                       SortTextAlign     : TAlignment;
                                       DisplayHorizAlign : TAlignment;
                                       DisplayVertAlign  : TAlignment;
                                       Visible           : Boolean;
                                       ReadOnly          : Boolean;
                                       Required          : Boolean;
                                       ColumnType        : _ColumnType;
                                       AutoComplete      : _AutocompleteType;
                                       WidgetType        : _WidgetType;
                                       TextInput         : _TextInputType;
                                       HTMLField         : _HTMLFieldType;
                                       HTMLClasses       : String;
                                       TextAction        : _TextInputAction;
                                       BracketType       : _BracketType;
                                       MetaMode          : _MetaMode;
                                       MetaFlags         : _MetaTypeFlags;

                                       TypeKind          : TTypeKind;
                                       FieldType         : TFieldType;
                                       ChildType         : String;
                                       AllowedChildren   : TStringDynArray;
                                       constructor Create ( const aName: String; aAutoComplete : _AutocompleteType ; aColumnType : _ColumnType );
                                       procedure SetColumnType ( aColumnType :_ColumnType );
                                       function    GetHTML ( aFlags : _StreamFlags; const aValue : TValue ):String;overload;
                                     end;

type _Enum                         = class
                                     private
                                       fName      : String;
                                       fCanAdd    : Boolean;
                                       fItems     : TStringDynArray;
                                       fMetaNames : TStringDynArray;
                                       fTag       : NativeUInt;
                                       function  GetItem     (aIdx: Integer): String;
                                       function  GetMetaName (aIdx: Integer): String;
                                       procedure SetItem     (aIdx: Integer; const aValue: String);
                                       procedure SetMetaName (aIdx: Integer; const aValue: String);
                                     public
                                       constructor Create ( const aName : String; const aDefaultCSV : String = ''; aMetaNameCSV : String = ''; aCanAdd : Boolean = False );
                                       function Count: Integer; inline;
                                       property Name                    : String read FName write FName;
                                       property CanAdd                  : Boolean read FCanAdd write FCanAdd;
                                       property Items                   : TStringDynArray read FItems write FItems;
                                       property MetaNames               : TStringDynArray read FMetaNames write FMetaNames;
                                       property Tag                     : NativeUInt read FTag    write FTag;
                                       property Item    [aIdx: Integer] : String     read GetItem     write SetItem;
                                       property MetaName[aIdx: Integer] : String     read GetMetaName write SetMetaName;
                                     end;
{$ENDREGION 'META TYPES'}

{$REGION 'CONTROL TYPES'}
///         UI CONTROLS         ///
type _LabelPos          = ( lpNone, lpLeft, lpTop, lpRight);
type _Icon              = record
                           Classes  : String;
                           Svg      : String;
                          end;
type _Control            = class
                           private
                            fParent   : _Control;
                            fName,
                            fDisplay,
                            fId,
                            fValue,
                            fDivClass : String;
                            fHead,
                            fBody,
                            fFoot     : String;
                            fChildren : TObjectList<_Control>;
                            procedure SetParent(const Value: _Control);
                           protected
                            constructor Create;virtual;
                            constructor CreateIn ( aParent : _Control; const aName, aDivClass, aHead, aBody, aFoot : String );overload;
                            destructor  Destroy;override;
                           public
                            function    Add<T:_Control>( const aName, aType : String ):T;
                            function GetHTML ( aFlags : _ViewFlags; const aStyle : String = '' ) : String;virtual;
                           published
                            property Parent   : _Control          read fParent    write SetParent;
                            property Name     : String            read fName      write fName;
                            property Display  : String            read fId        write fId;
                            property id       : String            read fId        write fId;
                            property Value    : String            read fId        write fId;
                            property DivClass : String            read fDivClass  write fDivClass;
                            property Head     : String            read fHead      write fHead;
                            property Body     : String            read fBody      write fBody;
                            property Foot     : String            read fFoot      write fFoot;
                            property Children : TObjectList<_Control> read fChildren;
                           end;
     _ControlClass       = class of _Control;

type _Frame              = class(_Control)
                           private
                           public
                           protected
                           published
                           end;
     _Form               = class(_Frame)
                           private
                           protected
                           public
                           end;

type _Input             = class(_Control)
                          private
                           fHtmlField   : _HTMLFieldType;
                           fInputType   : _TextInputType;
                           fAutoComplete: _AutocompleteType;
                           fLabelPos    : _LabelPos;
                           fIconSize    : Integer;
                           fIcon        : _Icon;
                           fFill        : String; // currentColor
                          public
                           constructor Create ( const aName, aValue: String; aMeta : _MetaType; const aDivClass:String = 'col-12 col-md-6'; aLabelPos : _LabelPos = lpTop; aFill : String = 'currentColor'; aViewFlags : _ViewFlags = [vfVert] );
//                           function GetHTML ( aFlags : _ViewFlags; const aStyle : String = '' ) : String;virtual;
                          published
                           property HtmlField   : _HTMLFieldType       read fHtmlField      write fHtmlField;
                           property InputType   : _TextInputType       read fInputType      write fInputType;
                           property AutoComplete: _AutocompleteType    read fAutoComplete   write fAutoComplete;
                           property LabelPos    : _LabelPos            read fLabelPos       write fLabelPos;
                           property Icon        : _Icon                read fIcon           write fIcon;
                           property Fill        : String               read fFill           write fFill;
                          end;

//     _DataControl        = class(_Control)
//                           private
//                           public
//                            Collection : String;
//                            Field      : String;
//                           published
//                           end;



{$ENDREGION 'CONTROL TYPES'}

{$REGION 'FIELDS,CLASSES'}
type _Class                       = class;
     _Obj                         = class;

     _FilterFunction              = reference to function ( const aObj : _Obj; aFlags : _StreamFlags; aParams : array of const ):Boolean;
     _FieldFlag                   = ( ffIndexed, ffPrimaryKey, ffHidden, ffRequired, ffReadOnly, ffWriteOnce, ffCalculated, ffAggregate, ffLookup , ffEmptyIsDefault, ffSetValueToDefaults, ffNoDelta );
     _FieldFlags                  = set of _FieldFlag;
     _Field                       = class
                                    private
                                     function GetValue(const aData: TVariant; const aName: String): TVariant;
                                     procedure SetValue(const aData: TVariant; const aName: String; const Value: TVariant);
                                    public
                                      Name         : String;
                                      Flags        : _FieldFlags;
                                      MetaType     : _MetaType;
                                      function    GetHTML ( aFlags : _StreamFlags; const aValue : TValue ):String;
                                      constructor Create ( const aName : String; aAutoComplete: _AutocompleteType; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 );overload;
                                      constructor Create ( const aName : String;  aColumnType : _ColumnType      ; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 );overload;
                                      function    GetHTMLInput( aFlags: _StreamFlags; aViewFlags:_ViewFlags; const aValue: TValue): String;

                                      property    Value[ const aData : TVariant; const aName : String ] : TVariant read GetValue write SetValue;
                                     end;
/// _FieldGroup, also for e.g. phone numbers or email addresses; "Anton<ant@tru.za>"<->Name,Address or Prefix/First/Middle/Family/Suffix <-> formatted name
/// With functions passed that have getters/setter params to calculate any calculated group
/// Perhaps include code that can update/notify parents/children/server/ui
     _FieldEnumSet                = class(_Field)
                                     private
                                      fItems  : TStringList;
                                      fCanAdd : Boolean;
                                      fSorted : Boolean;
                                      fIsSet  : Boolean;
                                      function GetItem(aIndex: Integer): String;
                                     public
                                      constructor Create ( const aName, aClassName: String; aParent : _Class; aDefaultCSV : String = ''; aCanAdd : Boolean = True );
                                      destructor  Destroy;override;
                                      function    ValidateArr (aItems : TStringDynArray):TStringDynArray; // Will check if correct, add new ones if allowed
                                      function    ToCSV ( aItems: TStringDynArray) : String;
                                      function    ToARR ( aCSV  :  String        ) : TStringDynArray;
                                      property Items : TStringList read fItems;
                                      property Item [aIndex : Integer] : String read GetItem;
                                      property CanAdd : Boolean read fCanAdd write fCanAdd;
                                      property Sorted : Boolean read fSorted write fSorted;
                                      property IsSet  : Boolean read fIsSet  write fIsSet;
                                     end;
     _FieldClass                   = class(_Field)
                                     public
                                      ObjDef : _Class;
                                      constructor Create ( const aName, aClassName: String; aParent : _Class  );
                                      destructor  Destroy;override;
                                     end;
     _FieldList                    = class(_FieldClass)
                                     public
                                     end;

///  _Query  ///
     _Query                        = record
                                      Name,
                                      Select,
                                      Where       : String;
                                      WhereParams : TValueDynArray;
                                     end;
     _QueryArr                     = array of _Query;
     _FieldQuery                   = class(_Field)
                                     private
                                     protected
                                      TableName    : String;
                                      Queries      : _QueryArr; // Add Groups/Queries; FIRST QUERY IS USUALLY *ALL* AND DEFAULT
                                      DefaultQuery : String;
                                      constructor Create ( const aName, aTableName : String; aQueries : _QueryArr = []; const aDefaultQuery : String ='' );
                                     public
                                     published
                                     end;
{ TAObj & TACol }
     _Class                        = class
                                     private
                                      fFields     : TObjectList<_Field>;
                                      fForms      : TObjectList<_Form>;
                                      function GetControl(const aName: String): _Control;
                                     public
                                      Name        : String;
                                      Parent      : _Class;
                                      Version     : Integer;  // <0 = custom version
                                      MetaName    : String;
                                      MetaType    : _MetaType;
                                      procedure Clear;
                                      constructor Create     ( const aName : String; aParent : _Class  );
                                      destructor  Destroy;override;
                                      function    GetFldDef(const aName: String): _Field;
                                      function    GetFldIdx(const aName: String): Integer;
                                      function    GetFieldsDBList:TArray<_Field>;
                                      function    GetFieldsCSV ( const aCSV : String = '') : TArray<_Field>;
                                      procedure   CheckDatasetFields ( aDataset : TDataset );
                                      procedure   CreateDatasetFields ( aDataset : TDataset );
                                      function    AddForm   ( const aName, aDivClass : String ):_Form;
                                      function    GetForm   ( const aName : String ) : _Form;
                                      function    Add       ( const aName : String; aAutoComplete: _AutocompleteType; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 ):_Class;overload;
                                      function    Add       ( const aName : String;  aColumnType : _ColumnType      ; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 ):_Class;overload;
                                      function    AddEnum   ( const aName : String;  aItemsCSV   : String           ; aFlags : _FieldFlags = []; aCanAdd : Boolean = False ):_Class;overload;
                                      function    AddSet    ( const aName : String;  aItemsCSV   : String           ; aFlags : _FieldFlags = []; aCanAdd : Boolean = False ):_Class;overload;
                                      function    AddClass  ( const aName, aClassName : String ):_Class;
                                      function    AddList   ( const aName, aClassName : String): _Class;
                                      function    Done   : _Class;
                                      property    Fields      : TObjectList<_Field> read fFields;
                                      property    Forms       : TObjectList<_Form > read fForms;
                                      property    Field   [ const aName : String ] : _Field read GetFldDef;
                                      property    Form    [ const aName : String ] : _Form read GetForm;
                                     end;
{$ENDREGION 'FIELDS,CLASSES'}

{$REGION 'OBJECTS,COLLECTIONS,BRANCHES,TREES'}
//type _ObjStreamFlags               = ( sfBinary );
     _ObjFlag                      = ( ofTable, ofRecordDelta, ofSingle, ofList, ofBranch, ofTree );
     _ObjFlags                     = set of _ObjFlag;

     _DeltaState                   = ( dsError, dsPosted, dsSumbitted, dsVerified );
     _DeltaStates                  = set of _DeltaState;
     _FieldDelta                   = record
                                      FieldIdx : Integer;
                                      A,B      : TValue;
                                     end;
     _FieldDeltaArr                = array of _FieldDelta;
     _ObjDelta                     = record
                                      ObjID    : Int64;
                                      DeltaNo  : Int64;
                                      Time     : TTimeStamp;
                                      State    : _DeltaStates;
                                      XtraInfo : TVariant;
                                     end;
     _ObjDeltaArr                  = array of _ObjDelta;


     _FieldCompareOps              = ( fcoNOOP , fcoEquals, fcoGreater, fcoSmaller, fcoGreaterAndEqual, fcoSmallerAndEqual, fcoContains  );
     _FieldCompareLogic            = ( fclValue, fclAnd   , fclOR     , fclXOR );
     _FieldCompare                 = record
                                      Name        : String;
                                      Ops         : _FieldCompareOps;
                                      OpsNot      : Boolean;
                                      Value       : String;
                                      ChildrenOps : _FieldCompareOps;
                                      Children    : array of _FieldCompare; //LEFT/RIGHT+
                                     end;

     _Obj                          = class
                                     private
                                      fCurrentRow  : Integer;
                                      fRows        : TArray<TValueDynArray>;
                                      fDefaults    : TValueDynArray;
                                      fUpdateCount : Integer; // TODO : Use to let cross-referenced calced fields stop broadcasting changes in Setters; Optionally postpone until Endupdate
                                      function  GetRowCount : Integer;
                                      function  GetRowNo    : Integer;
                                      procedure SetRowNo  (const Value: Integer);
                                      function  GetRow    (aRowNo: Integer): TValueDynArray;
                                      procedure SetRow    (aRowNo: Integer; const Value: TValueDynArray);
                                      function  GetCel    (aRowNo, aColNo: Integer): TValue;
                                      procedure SetCel    (aRowNo, aColNo: Integer; const Value: TValue);
                                      procedure ExpandTo  (aRowNo : Integer);
                                      function  GetValue  (aColNo: Integer): TValue;
                                      procedure SetValue  (aColNo: Integer; const Value: TValue);
                                      function  GetField  (aColNo: Integer): _Field;
                                      function  GetMeta   (aColNo: Integer): _MetaType;
                                      function  GetCelStr (aRowNo, aColNo: Integer): String;
                                      procedure SetCelStr (aRowNo, aColNo: Integer; const Value: String);
                                      function  GetColCount: Integer;
                                     public
                                      Key        : String;
                                      Name       : String;
                                      ObjDef     : _Class;
                                      Flags      : _ObjFlags;
                                      Tags       : TStringList; // Name=Value pair of abbitrary data, can also temorarily hold objects but is not saved to Bin
                                      OwnsObjDef : Boolean;
                                      fDelta     : _ObjDeltaArr;

                                      constructor Create (  const aKey, aName : String; const aObjDef : _Class; aOwnsObjDef : Boolean = False );
                                      destructor  Destroy;override;
                                      procedure   Clear ( aOnlyData : Boolean = True );
                                      /// BeginUpdate should make a snapshot (if not yet) of original fields
                                      /// EndUpdate record just new values at each Delta
                                      /// Delta Changes are waited to be synced and verified, then removed
                                      /// I.o.w. modified objects will always have an original snapshot copy of all fields, and a 'live' local master copy.
                                      /// FieldKind; fkData, fkCalculated, fkInternalCalc, fkLookup, fkAggregate
                                      /// FieldPersist; fpAbsolute, fpRelative, fp
                                      /// Unit
                                      procedure   BeginUpdate;
                                      procedure   EndUpdate;
                                      function    ChangeCount:Integer;

                                      function    CopyOneToDataset    ( aDataset: TDataset): String;
                                      function    CopyOneFromDataset  ( aDataset: TDataset): String;
                                      function    CopyAllToDataset    ( aDataset: TDataset): String;
                                      function    CopyAllFromDataset  ( aDataset: TDataset): String;

                                      function    Query               ( const aSelectCSV, aWhere : String; const aWhereParams : array of const ):TValueDynArrayArr;
                                      function    GetRows             (             const aWhere : String; const aWhereParams : array of const ) : TIntegerDynArray;
                                      function    GetRoute : String;

                                      function    GetFormTemplate : String;

                                      //                                      function    GetHTML ( aFlags : _StreamFlags ):String;

                                      /// Function that should be able to return just the (column+row filtered) JSON object/data, the HTML
                                      /// Html empty, populated, styled, templates for elements
                                      /// CSS, JS code if needed.
//                                      function    Stream( aStream: TStream; aKind : _StreamKind; aFlags: _StreamFlags; const aFields: TStringDynArray; aFilter: _FilterFunction): NativeInt;
                                      function  FromStreamBin      (aStream : TStream): NativeInt;
                                      function  ToStreamBin        (aStream : TStream): NativeInt;
                                      function  CellIsDefault      ( aColNo , aRowNo : Integer ) : Boolean;
                                      function  ValueIsDefault     ( aColNo          : Integer; aValue : String ): Boolean;
                                      function  TryDefaultValue    ( aColNo : Integer; out aValue : TValue):Boolean;
//                                      function  GetByName<T>(const aName:String ) : T;
//                                      procedure SetByName<T>(const aName:String; aValue : T );

//                                      function HasField      ( const aName : String; aTypeKind : TTypeKind):Integer;
                                      property  RowCount : Integer   read GetRowCount;
                                      property  RowNo    : Integer   read GetRowNo   write SetRowNo;
                                      property  ColCount : Integer   read GetColCount;
                                      property  Field  [ aColNo         : Integer ] : _Field        read GetField;
                                      property  Row    [ aRowNo         : Integer ] : TValueDynArray read GetRow     write SetRow;
                                      property  Cell   [ aRowNo, aColNo : Integer ] : TValue         read GetCel     write SetCel;
                                      property  CellStr[ aRowNo, aColNo : Integer ] : String         read GetCelStr  write SetCelStr;
                                      property  Value  [         aColNo : Integer ] : TValue         read GetValue   write SetValue;
                                      property  Meta   [         aColNo : Integer ] : _MetaType      read GetMeta;
//                                     protected
//                                      property ByName<T> [         aName  : String  ] : T              read GetByName;
                                     end;
     _Col                          = class(_Obj)
                                     private
                                      fMetaVer  : Integer;
                                      fDataVer  : Integer;
                                      fResource : String;
                                     public
                                     published
                                      property MetaVer  : Integer read fMetaVer  write fMetaVer;
                                      property DataVer  : Integer read fMetaVer  write fMetaVer;
                                      property Resource : String  read fResource write fResource;
                                     end;
{$ENDREGION 'OBJECTS,COLLECTIONS,BRANCHES,TREES'}

{$REGION 'ROUTES, ETC.'}
type _Route                      = class
                                   public
                                    Name   : String;
                                    Path   : String;
                                    Params : TParams;
                                    constructor Create ( const aName, aPath : String );
                                    destructor  Destroy;override;
                                    procedure   Execute ( aParams : TParams );virtual;abstract;
                                   end;

type _RouteCollection            = class(_Route)
                                   public
                                    Collection : _Obj;
                                    constructor Create ( const aName, aPath : String; aCollection : _Obj );
                                    //procedure   Execute ( aParams : TParams );override;
                                   end;
{$ENDREGION 'ROUTES, ETC.'}

{$REGION 'APP'}
{ _App }
type _WhereFunc                  = reference to function ( const aRow : TValueDynArray ) : Boolean;
type _App                        = class(TComponent)
                                    private
                                       fIcons       : TDictionary<String,_Icon     >;
                                       fMetaTypes   : TObjectDictionary<String,_MetaType >;
                                       fEnums       : TObjectDictionary<String,_Enum     >;
                                       fClasses     : TObjectDictionary<String,_Class    >;
                                       fCollections : TObjectDictionary<String,_Col      >;
                                       fRoutes      : TObjectDictionary<String,_Route    >;
//                                       fTemplates   : TDictionary      <String,String    >; // TODO : Use instances for each template that pre-prepare mustaches+partials; but that make update issues.
                                       fAppVersion  : Integer;
                                       fVmtVersion  : Integer;
//                                       fFileService : TFileService;
                                       function  GetMetaType(const aName: String): _MetaType;
                                       procedure SetMetaType(const aName: String; const Value: _MetaType);
                                       function  GetCollection(const aName: String): _Col;
                                       function  GetEnum(const aName: String): _Enum;
                                       function  GetObjDef(const aName: String): _Class;
                                       procedure SetCollection(const aName: String; const Value: _Col);
                                       procedure SetEnum(const aName: String; const Value: _Enum);
                                       procedure SetObjDef(const aName: String; const Value: _Class);
                                       function  GetKeyPath(const aPath: TStringDynArray; const aIndex: Integer): TObject;
                                       function  GetIcon(const aName: String): _Icon;
                                       procedure SetIcon(const aName: String; aValue:_Icon);
//                                       function Ge_MetaType( const aInfoName: String): _MetaType;
                                    public
                                       constructor Create(AOwner: TComponent); override;
                                       destructor  Destroy; override;
                                       procedure SetupDefaults;virtual;
                                       procedure SetupMetaTypes;virtual;
                                       procedure SetupEnums;virtual;
                                       procedure SetupClasses;virtual;
                                       procedure SetupCollections;virtual;
                                       procedure SetupRoutes;virtual;
                                       procedure SetupIcons;virtual;

                                       procedure MessageReceived ( aSource : TObject; const aMessage : String );virtual;
                                       procedure CommandReceived ( aSource : TObject; aCmd : TWSMsgCmds; const aData : JSValue );virtual;
                                       function  GetTemplate     ( const aName : String ):String;virtual;abstract;

                                       function RetrieveList     ( const aTableName, aSelect : String; aWhere : _WhereFunc; aEnsureIntegrity : Boolean = False ) : JSValue;virtual;
                                       function OpenCollection   ( const aTableName          : String;                      aEnsureIntegrity : Boolean = False ) : JSValue;virtual;
                                       function SanitizePhoneNumber(const PhoneNumber: string): string;virtual;abstract;
//                       [async]
//                       function Retrieve ( aTable, aSelect : String; aWhere : _WhereFunc = nil ):TTableContent;

                                       procedure AddMeta       ( const aMeta  : _MetaType);
                                       procedure AddEnum       ( const aEnum  : _Enum    );
                                       procedure AddClass      ( const aClass : _Class   );
                                       procedure AddCollection ( const aCol   : _Col     );
//                                       function    AddMetaInfo( aInfoName : String  ): _MetaType;
//                                       property    MetaType [ aName : String ] : _MetaType read Ge_MetaType;
//                                       property    Enum     [ aName : String ] : _Enum read Ge_Enum;
//                                       property    &Class   [ aName : String ] : _MetaType read Ge_MetaType;

                                       function    GetKeyItem(const aPath: String): TObject;
                                       property    KeyItem    [ const aPath : String ] : TObject read GetKeyItem;

                                       property    MetaType   [ const aName : String ] : _MetaType read GetMetaType   write SetMetaType;
                                       property    Icon       [ const aName : String ] : _Icon     read GetIcon       write SetIcon;
                                       property    Enum       [ const aName : String ] : _Enum     read GetEnum       write SetEnum;
                                       property    ObjDefs     [ const aName : String ] : _Class    read GetObjDef     write SetObjDef;
                                       property    Collection [ const aName : String ] : _Col      read GetCollection write SetCollection;
                                      published
                                       property    MetaTypes  : TObjectDictionary      <String,_MetaType> read FMetaTypes;
                                       property    Enums      : TObjectDictionary      <String,_Enum    > read FEnums;
                                       property    Classes    : TObjectDictionary      <String,_Class   > read FClasses;
                                       property    Collections: TObjectDictionary      <String,_Col     > read FCollections;
                                       property    Icons      : TDictionary            <String,_Icon    > read FIcons;

                                       property    AppVersion : Integer                             read fAppVersion write fAppVersion;
                                       property    VmtVersion : Integer                             read fVmtVersion write fVmtVersion;
                                    end;
{$ENDREGION 'APP'}

{$REGION 'BitBuffer'}

/// Buffer for fast streaming bits.
/// Buffer is zero'd so streasming bit is a simple AND
/// For performance, NO RANGE CHECK ARE DONE
///  ..Preferably do periodic 'BitsAvail' checks and move/clear buffer
/// [TStringHandler] is an object that can be passed to en/decrypt strings, e.g.
///  * fast 6bit packing , special one for loCase-fieldnames, special huffman for json/html
///
///  [TBitBuffer] is a 64k on-stack buffer
type TBitBuffer                      = record
                                        Dat : Array of Byte;
                                        Pos : NativeUInt;
                                        procedure init( aByteSize : Integer = $FFFF );
                                        function  Start     : Pointer;inline;
                                        function  BitsUsed  : NativeUInt;inline;
                                        function  BitsAvail : NativeUInt;inline;

                                        procedure PutBit ( aOn     : Boolean );inline;
                                        procedure PutBits( aData   : Pointer ; aCount : Integer );inline;

                                        function  GetBit  : Boolean;inline;
                                        procedure GetBits( aData   : POinter ; aCount : Integer ) ;inline;
                                       end;
{$ENDREGION "BitBuffer"}

type TWSSessionInfo        = record
                              DeviceID  : String;
                              SessionID : String;
                              UserID    : Int64;
                              UserName  : String;
                              UserPhoto : Int64;
                              UserEMail : String;
                              UserPhone : String;
                            end;


 { Misscel helpers }
function StringToStream(aStream: TStream; const aStr: String): NativeInt;
function StringFromStream(aStream: TStream; var aBytesRead: NativeInt): String;overload;
function StringFromStream(aStream: TStream                           ): String;overload;
function StringToAutocomplete(const Value: string): _AutocompleteType;
function CSVStrtoArr ( const aCSV : String ) : TStringDynArray;
function CSVArrToStr ( const aArr : TStringDynArray):String;

function AutocompleteToString(AutoType: _AutocompleteType): string;
function AutocompleteToColType( aAuto : _AutocompleteType ) : _ColumnType;
function AutocompleteToWidgetType(aAuto: _AutocompleteType): _WidgetType;
function AutocompleteToTextInputType(aAuto: _AutocompleteType): _TextInputType;
function AutocompleteToHTMLFieldType(aAuto: _AutocompleteType): _HTMLFieldType;

var App : _App;

implementation


{ Helper functions }

function CSVStrtoArr ( const aCSV : String ) : TStringDynArray;
var SL  : TStringList;
    A   :  TArray<String>;
begin
 SL  := TStringList.Create;
 SL.Delimiter:=',';
 SL.StrictDelimiter:=True;
 SL.DelimitedText:=aCSV;
 A:=SL.ToStringArray;
 Result:=A;
 SL.Free;
end;

function CSVArrToStr ( const aArr : TStringDynArray):String;
var SL  : TStringList;
    A   :  TArray<String>;
begin
 SL  := TStringList.Create;
 SL.Delimiter:=',';
 SL.StrictDelimiter:=True;
 SL.AddStrings(aArr);
 Result:=SL.DelimitedText;
 SL.Free;
end;


function AutocompleteToColType(aAuto: _AutocompleteType): _ColumnType;
begin
  case aAuto of
    ac_OFF: exit(ctNone);
    ac_ON : exit(ctString);
    acName: exit(ctName);
    acHonorificPrefix: exit(ctString);         // Honorific Prefix (e.g., Mr., Dr.) is a string, not a name
    acGivenName: exit(ctName);
    acAdditionalName: exit(ctName);
    acFamilyName: exit(ctName);
    acHonorificSuffix: exit(ctString);         // Honorific Suffix (e.g., Jr., PhD) is a string
    acNickname: exit(ctString);                // Nickname is a string
    acEmail: exit(ctEmail);
    acUsername: exit(ctString);                // Username is a string
    acNewPassword: exit(ctPassword);
    acCurrentPassword: exit(ctPassword);
    acOrganizationTitle: exit(ctString);       // Title in an organization is a string
    acOrganization: exit(ctString);            // Organization name is a string
    acTel: exit(ctPhone);
    acTelCountryCode: exit(ctInteger);         // Country code is a number
    acTelNational: exit(ctPhone);
    acTelAreaCode: exit(ctInteger);            // Area code is a number
    acTelLocal: exit(ctPhone);
    acTelExtension: exit(ctInteger);           // Phone extension is a number
    acImpp: exit(ctString);                    // Instant Messaging Protocol (IMPP) is a string
    acStreetAddress: exit(ctAddress);
    acAddressLine1: exit(ctAddress);
    acAddressLine2: exit(ctAddress);
    acAddressLine3: exit(ctAddress);
    acAddressLevel1: exit(ctCity);             // City or region
    acAddressLevel2: exit(ctCity);             // City or locality
    acAddressLevel3: exit(ctCity);             // Additional locality
    acAddressLevel4: exit(ctCity);             // Additional locality
    acCountry: exit(ctCountry);
    acCountryName: exit(ctCountry);
    acCountryCode: exit(ctString);             // Country code is a string
    acPostalCode: exit(ctPostalCode);
    acCcName: exit(ctName);
    acCcGivenName: exit(ctName);
    acCcFamilyName: exit(ctName);
    acCcNumber: exit(ctString);                // Credit card number is a string
    acCcExp: exit(ctDate);                     // Expiry date is a date
    acCcExpMonth: exit(ctInteger);             // Expiry month is an integer
    acCcExpYear: exit(ctInteger);              // Expiry year is an integer
    acCcCsc: exit(ctString);                   // Credit card CSC is a string
    acCcType: exit(ctString);                  // Credit card type is a string
    acTransactionAmount: exit(ctCurrency);
    acTransactionCurrency: exit(ctString);     // Currency is a string
    acLanguage: exit(ctString);                // Language is a string
    acBday: exit(ctDate);
    acBdayDay: exit(ctInteger);
    acBdayMonth: exit(ctInteger);
    acBdayYear: exit(ctInteger);
    acSex: exit(ctSet);
    acUrl: exit(ctUrl);
    acPhoto: exit(ctNone);                     // Photo field is not relevant for ColumnType
  end;
end;

function AutocompleteToWidgetType(aAuto: _AutocompleteType): _WidgetType;
begin
  case aAuto of
    ac_OFF: exit(wtNone);
    ac_ON : exit(wtEditText);
    acName, acHonorificPrefix, acGivenName, acAdditionalName, acFamilyName, acHonorificSuffix,
    acNickname, acUsername, acOrganizationTitle, acOrganization, acLanguage: exit(wtEditText); // Text inputs
    acEmail: exit(wtEditText);
    acNewPassword, acCurrentPassword: exit(wtEditText);  // Password inputs
    acTel, acTelCountryCode, acTelNational, acTelAreaCode, acTelLocal, acTelExtension: exit(wtEditText); // Phone inputs
    acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2,
    acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acCountryCode, acPostalCode: exit(wtEditText); // Address inputs
    acCcName, acCcGivenName, acCcFamilyName, acCcNumber, acCcCsc, acCcType: exit(wtEditText);  // Credit card fields
    acCcExp, acCcExpMonth, acCcExpYear, acBday, acBdayDay, acBdayMonth, acBdayYear: exit(wtDateTimeEdit); // Date/Time inputs
    acTransactionAmount: exit(wtEditText);
    acUrl: exit(wtEditText);                    // URL input
    acSex: exit(wtRadioGroup);                  // Radio group for sex
    acPhoto: exit(wtFile);                      // File input for photo
    acImpp: exit(wtEditText);                   // Instant Messaging input
    else exit(wtNone);
  end;
end;

function AutocompleteToTextInputType(aAuto: _AutocompleteType): _TextInputType;
begin
  case aAuto of
    ac_OFF: exit(ttNone);
    ac_ON : exit(ttText);
    acName, acHonorificPrefix, acGivenName, acAdditionalName, acFamilyName, acHonorificSuffix,
    acNickname, acUsername, acOrganizationTitle, acOrganization, acLanguage: exit(ttText);
    acEmail: exit(ttEmailAddress);
    acNewPassword, acCurrentPassword: exit(ttVisiblePassword);
    acTel, acTelCountryCode, acTelNational, acTelAreaCode, acTelLocal, acTelExtension: exit(ttPhone);
    acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2,
    acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acCountryCode, acPostalCode: exit(ttStreetAddress);
    acCcName, acCcGivenName, acCcFamilyName, acCcNumber, acCcCsc, acCcType: exit(ttText);
    acCcExp, acCcExpMonth, acCcExpYear, acBday, acBdayDay, acBdayMonth, acBdayYear: exit(ttDateTime);
    acTransactionAmount: exit(ttCurrency);
    acUrl: exit(ttURL);
    acSex: exit(ttNone); // Handled differently
    acPhoto: exit(ttNone); // File input type handled differently
    acImpp: exit(ttText);
    else exit(ttNone);
  end;
end;

function AutocompleteToHTMLFieldType(aAuto: _AutocompleteType): _HTMLFieldType;
begin
  case aAuto of
    ac_OFF: exit(dtNone);
    ac_ON : exit(dtText);
    acName, acHonorificPrefix, acGivenName, acAdditionalName, acFamilyName, acHonorificSuffix,
    acNickname, acUsername, acOrganizationTitle, acOrganization, acLanguage: exit(dtText);
    acEmail: exit(dtEmail);
    acNewPassword, acCurrentPassword: exit(dtPassword);
    acTel, acTelCountryCode, acTelNational, acTelAreaCode, acTelLocal, acTelExtension: exit(dtTel);
    acStreetAddress, acAddressLine1, acAddressLine2, acAddressLine3, acAddressLevel1, acAddressLevel2,
    acAddressLevel3, acAddressLevel4, acCountry, acCountryName, acCountryCode, acPostalCode: exit(dtText); // General text input
    acCcName, acCcGivenName, acCcFamilyName, acCcNumber, acCcCsc, acCcType: exit(dtText);
    acCcExp, acCcExpMonth, acCcExpYear, acBday, acBdayDay, acBdayMonth, acBdayYear: exit(dtDate);
    acTransactionAmount: exit(dtNumber);
    acUrl: exit(dtURL);
    acSex: exit(dtRadio); // Radio input for sex
    acPhoto: exit(dtFile); // File input for photo
    acImpp: exit(dtText);
    else exit(dtNone);
  end;
end;

function AutocompleteToString(AutoType: _AutocompleteType): string;
begin
  Result := AutocompleteStrings[AutoType];
end;

function StringToAutocomplete(const Value: string): _AutocompleteType;
var
  i: _AutocompleteType;
begin
  for i := Low(_AutocompleteType) to High(_AutocompleteType) do
  begin
    if AutocompleteStrings[i] = Value then
      Exit(i);
  end;
  raise Exception.Create('Invalid autocomplete string: ' + Value);
end;

function StringToStream(aStream: TStream; const aStr: String): NativeInt;
var StringBytes: TBytes;
    StringLength: Integer;
begin
  StringBytes  := TEncoding.UTF8.GetBytes(aStr);
  StringLength := Length(StringBytes);
  aStream.WriteData(StringLength);
  aStream.WriteBuffer(StringBytes, StringLength);
  Result := SizeOf(StringLength) + StringLength;
end;

function StringFromStream(aStream: TStream                           ): String;overload;
var TmpInt : Integer;
begin
 Result:=StringFromStream(aStream);
end;

function StringFromStream(aStream: TStream; var aBytesRead: NativeInt): String;
var StringLength: Integer;
    StringBytes: TBytes;
begin
  aStream.ReadData(StringLength, SizeOf(StringLength));
  SetLength(StringBytes, StringLength);
  aStream.ReadBuffer(StringBytes, StringLength);
  Result := TEncoding.UTF8.GetString(StringBytes);
  aBytesRead := SizeOf(StringLength) + StringLength;
end;

{ _MetaType }

constructor _MetaType.Create( const aName: String; aAutoComplete : _AutocompleteType ; aColumnType : _ColumnType );
begin
 Name              := aName;
 DisplayName       := aName;
 DisplayPlural     := aName;
 Description       := aName;
 Icon              := aName;
 IconSelected      := aName;
 AutoComplete      := aAutoComplete;
 SetColumnType(aColumnType);
 if aAutoComplete<>ac_OFF
    then begin
          WidgetType := AutocompleteToWidgetType    (AutoComplete);
          TextInput  := AutocompleteToTextInputType (AutoComplete);
          HTMLField  := AutocompleteToHTMLFieldType (AutoComplete);
         end;

end;

function _MetaType.GetHTML(aFlags: _StreamFlags; const aValue: TValue): String;
begin
 case WidgetType of
  wtNone         : ;
  wtBreak        : ;
  wtForm         : ;
  wtPanel        : ;
  wtListBox      : ;
  wtEditText     : begin
                    case ColumnType of
                     ctNone: ;
                     ctInteger: ;
                     ctDouble: ;
                     ctCurrency: ;
                     ctTimeLog: ;
                     ctDateTime: ;
                     ctDate: ;
                     ctTime: ;
                     ctBoolean: ;
                     ctEnums: ;
                     ctSet: ;
                     ctString: ;
                     ctMultiline: ;
                     ctName: ;
                     ctPassword: ;
                     ctPhone: ;
                     ctEmail: ;
                     ctAddress: ;
                     ctCity: ;
                     ctCountry: ;
                     ctPostalCode: ;
                     ctUrl: ;
                     ctFileName: ;
                     ctIpv4: ;
                     ctIpv6: ;
                     ctTID: ;
                     ctFileExt: ;
                     ctMimeType: ;
                     ctBlobStr: ;
                     ctFile: ;
                     ctCel: ;
                     ctFax: ;
                     ctIDnum: ;
                     ctRegNo: ;
                     ctVatNo: ;
                     ctGPS: ;
                     ct_QUERY: ;
                    end;
                   end;
  wtMemo         : ;
  wtComboBox     : ;
  wtCheckBox     : ;
  wtCheckGroup   : ;
  wtRadioGroup   : ;
  wtDateTimeEdit : ;
  wtButton       : ;
  wtFile         : ;
  wtImage        : ;
  wtAvatar       : ;
 end;
end;

procedure _MetaType.SetColumnType( aColumnType: _ColumnType);
begin
 DisplayWidth      := 8;
 Icon              := 'Number';
 IconSelected      := 'Number';
 DisplayPerc       := 0;
 SortStrSize       := 0;
 SearchPrior       := 0;
 SortNumber        := 0;
 SortTextAlign     := taLeftJustify;
 DisplayHorizAlign := taLeftJustify;
 DisplayVertAlign  := taCenter;
 Visible           := True;
 ReadOnly          := False;
 Required          := False;
 ColumnType        := aColumnType;
 WidgetType        := wtEditText;
 TextInput         := ttNone;
 HTMLField         := dtNone;
 TextAction        := _TextInputAction.taNone;
 BracketType       := _BracketType.btNone;
 MetaMode          := _MetaMode.mmNone;
 MetaFlags         := [];

 TypeKind          := tkUnknown;
 FieldType         := ftUnknown;
 ChildType         := '';
 AllowedChildren   := [];

 case aColumnType of
  ctNone      : ;
  ctInteger   : begin
                 DisplayWidth      := 8;
                 Icon              := 'Number';
                 IconSelected      := 'Number';
                 SortTextAlign     := taRightJustify;
                 DisplayHorizAlign := taRightJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttInteger;
                 HTMLField         := dtNumber;
                 TypeKind          := tkInteger;
                 FieldType         := ftInteger;
                end;
  ctDouble    : begin
                 DisplayWidth      := 8;
                 Icon              := 'FloatNumber';
                 IconSelected      := 'FloatNumber';
                 SortTextAlign     := taRightJustify;
                 DisplayHorizAlign := taRightJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttDouble;
                 HTMLField         := dtNumber;
                 TypeKind          := tkFloat;
                 FieldType         := ftFloat;
                end;
  ctCurrency  : begin
                 DisplayWidth      := 8;
                 Icon              := 'Currency';
                 IconSelected      := 'Currency';
                 SortTextAlign     := taRightJustify;
                 DisplayHorizAlign := taRightJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttDouble;
                 HTMLField         := dtNumber;
                 TypeKind          := tkFloat;
                 FieldType         := ftFloat;
                end;
  ctTimeLog,
  ctDate,
  ctTime      : begin
                 Assert(False);
                end;
  ctDateTime  : begin
                 DisplayWidth      := 16;
                 Icon              := 'DateTime';
                 IconSelected      := 'DateTime';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttDateTime;
                 HTMLField         := dtDateTimeLocal;
                 TypeKind          := tkFloat;
                 FieldType         := ftDateTime;
                end;
  ctBoolean   : begin
                 DisplayWidth      := 1;
                 Icon              := 'Boolean';
                 IconSelected      := 'Boolean';
                 SortTextAlign     := taCenter;
                 DisplayHorizAlign := taCenter;
                 WidgetType        := wtCheckBox;
                 TextInput         := ttNone;
                 HTMLField         := dtCheckbox;
                 TypeKind          := tkInteger;
                 FieldType         := ftBoolean;
                end;
  ctString    : begin
                 DisplayWidth      := 15;
                 Icon              := 'Text';
                 IconSelected      := 'Text';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                 case AutoComplete of
                  ac_OFF: ;
                  acName            : begin
                 DisplayWidth      := 15;
                 Icon              := 'Text';
                 IconSelected      := 'Text';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;

                                      end;
                  acHonorificPrefix: ;
                  acGivenName: ;
                  acAdditionalName: ;
                  acFamilyName: ;
                  acHonorificSuffix: ;
                  acNickname: ;
                  acEmail: ;
                  acUsername: ;
                  acNewPassword: ;
                  acCurrentPassword: ;
                  acOrganizationTitle: ;
                  acOrganization: ;
                  acTel: ;
                  acTelCountryCode: ;
                  acTelNational: ;
                  acTelAreaCode: ;
                  acTelLocal: ;
                  acTelExtension: ;
                  acImpp: ;
                  acStreetAddress: ;
                  acAddressLine1: ;
                  acAddressLine2: ;
                  acAddressLine3: ;
                  acAddressLevel1: ;
                  acAddressLevel2: ;
                  acAddressLevel3: ;
                  acAddressLevel4: ;
                  acCountry: ;
                  acCountryName: ;
                  acCountryCode: ;
                  acPostalCode: ;
                  acCcName: ;
                  acCcGivenName: ;
                  acCcFamilyName: ;
                  acCcNumber: ;
                  acCcExp: ;
                  acCcExpMonth: ;
                  acCcExpYear: ;
                  acCcCsc: ;
                  acCcType: ;
                  acTransactionAmount: ;
                  acTransactionCurrency: ;
                  acLanguage: ;
                  acBday: ;
                  acBdayDay: ;
                  acBdayMonth: ;
                  acBdayYear: ;
                  acSex: ;
                  acUrl: ;
                  acPhoto: ;
                 end;
                end;
  ctMultiline : begin
                 DisplayWidth      := 20;
                 Icon              := 'Memo';
                 IconSelected      := 'Memo';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtMemo;
                 TextInput         := ttMultiline;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctName      : begin
                 DisplayWidth      := 15;
                 Icon              := 'Name';
                 IconSelected      := 'Name';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttName;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctPassword  : begin
                 DisplayWidth      := 15;
                 Icon              := 'Password';
                 IconSelected      := 'Passsword';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttVisiblePassword;
                 HTMLField         := dtPassword;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctPhone     : begin
                 DisplayWidth      := 15;
                 Icon              := 'PhoneNo';
                 IconSelected      := 'PhoneNo';
                 SortTextAlign     := taRightJustify;
                 DisplayHorizAlign := taRightJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttPhone;
                 HTMLField         := dtTel;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctEmail     : begin
                 DisplayWidth      := 15;
                 Icon              := 'EMail';
                 IconSelected      := 'EMail';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttEmailAddress;
                 HTMLField         := dtEmail;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctAddress : begin
                 DisplayWidth      := 15;
                 Icon              := 'StreetAddress';
                 IconSelected      := 'StreetAddress';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttStreetAddress;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctCity      : begin
                 DisplayWidth      := 15;
                 Icon              := 'City';
                 IconSelected      := 'City';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctCountry   : begin
                 DisplayWidth      := 15;
                 Icon              := 'Country';
                 IconSelected      := 'Country';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctPostalCode : begin
                 DisplayWidth      := 5;
                 Icon              := 'PostalCode';
                 IconSelected      := 'PostalCode';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctUrl       : begin
                 DisplayWidth      := 15;
                 Icon              := 'URL';
                 IconSelected      := 'URL';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttURL;
                 HTMLField         := dtURL;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctFileName  : begin
                 DisplayWidth      := 15;
                 Icon              := 'Text';
                 IconSelected      := 'Text';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;
  ctIpv4      : begin
                 DisplayWidth      := 15;
                 Icon              := 'IPv4';
                 IconSelected      := 'IPv4';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkInteger;
                 FieldType         := ftInteger;
                end;
  ctIpv6      : begin
                 DisplayWidth      := 25;
                 Icon              := 'IPv6';
                 IconSelected      := 'IPv6';
                 SortTextAlign     := taRightJustify;
                 DisplayHorizAlign := taRightJustify;
                 WidgetType        := wtEditText;
                 TextInput         := ttText;
                 HTMLField         := dtText;
                 TypeKind          := tkString;
                 FieldType         := ftString;
                end;

   ctEnums     : begin
                 DisplayWidth      := 10;
                 Icon              := 'ENum';
                 IconSelected      := 'ENum';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtRadioGroup;
                 TextInput         := ttNone;
                 HTMLField         := dtRadio;
                 TypeKind          := tkEnumeration;
                 FieldType         := ftInteger;
                end;
  ctSet       : begin
                 DisplayWidth      := 10;
                 Icon              := 'ENum';
                 IconSelected      := 'ENum';
                 SortTextAlign     := taLeftJustify;
                 DisplayHorizAlign := taLeftJustify;
                 WidgetType        := wtCheckGroup;
                 TextInput         := ttNone;
                 HTMLField         := dtCheckbox;
                 TypeKind          := tkSet;
                 FieldType         := ftInteger;
                end;
 end;
end;

{ _Enum }

function _Enum.Count: Integer;
begin
  Result := Length(FItems);
end;

constructor _Enum.Create( const aName : String; const aDefaultCSV : String = ''; aMetaNameCSV : String = ''; aCanAdd : Boolean = False );
var SL : TStringList;
    S  : String;
begin
 Name := aName;
 SL   := TStringList.Create;
 if aDefaultCSV<>''
    then begin
          SL.DelimitedText:=aDefaultCSV;
          fItems := SL.ToStringArray;
          SL.Clear;
         end;
 if aMetaNameCSV<>''
    then begin
          SL.DelimitedText:=aMetaNameCSV;
          fMetaNames := SL.ToStringArray;
          SL.Clear;
         end;
 SL.Free;
end;

function _Enum.GetMetaName(aIdx: Integer): String;
begin
  if aIdx >= Length(fMetaNames)
     then Result := GetItem(aIdx)
     else Result := fMetaNames[aIdx];
end;

function _Enum.GetItem(aIdx: Integer): String;
begin
  if aIdx >= Count then
    Result := ''
  else
    Result := FItems[aIdx];
end;

procedure _Enum.SetMetaName(aIdx: Integer; const aValue: String);
begin
 if aIdx >= Length(fMetaNames)
    then SetLength(fMetaNames, Succ(aIdx));
 fMetaNames[aIdx] := aValue;
end;

procedure _Enum.SetItem(aIdx: Integer; const aValue: String);
begin
  if aIdx >= Length(FItems)
     then begin
           SetLength(fItems    , Succ(aIdx));
           SetLength(fMetaNames, Succ(aIdx));
          end;
  fItems[aIdx] := aValue;
end;

{ _FldDef }

constructor _Field.Create( const aName : String; aColumnType : _ColumnType; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 );
begin
 Name         := aName;
 Flags        := aFlags;
 MetaType     := _MetaType.Create(aName,ac_OFF,aColumnType);
 if ffPrimaryKey in Flags
    then begin
          MetaType.Required:=True;
          Include(Flags,ffIndexed);
         end;
end;

constructor _Field.Create( const aName: String; aAutoComplete: _AutocompleteType; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 );
begin
 Name         := aName;
 Flags        := [];
 MetaType     := _MetaType.Create(aName,aAutoComplete,AutocompleteToColType(aAutoComplete));
 if ffPrimaryKey in Flags
    then begin
          MetaType.Required:=True;
          Include(Flags,ffIndexed);
         end;
end;


///   HTML   ///
function _Field.GetHTMLInput( aFlags: _StreamFlags; aViewFlags:_ViewFlags; const aValue: TValue): String;
var Svg : _Icon;
    SL  : TStringList;
begin
 if not (vfNoIcon in aViewFlags)
    then Svg := App.fIcons[MetaType.Icon]
    else Svg.Svg:='';
// Result:=
//'''
// <div class="{{divClass}}">');
//   <label for="{{name}}" class="form-label">{{meta.DisplayName}}+'</label>');
//   <div class="input-group">');
//     <span class="input-group-text">');
//       <svg xmlns="http://www.w3.org/2000/svg" width="{{icon.size}}" height="{{icon.size}}" fill="currentColor" class="{{icon.classes}} viewBox="0 0 {{icon.size}} {{icon.size}}">');
//         <path d="{{icon.path}} />');
//       </svg>');
//     </span>');
//     <input type="{{meta.HTMLField}}" class="form-control" id="{{name}}" name="{{name}}" value="{{value}}">');
//   </div>');
// </div>');
//''';

 case MetaType.ColumnType of
  ctPhone          :  begin
                       if not (vfNoLabel in aViewFlags)
                          then begin
                                if (vfLabelTop in aViewFlags)
                                   then begin

                                        end;
                               end;
                      end;
 end;
end;

function _Field.GetHTML(aFlags: _StreamFlags; const aValue: TValue): String;
begin
 case MetaType.ColumnType of
  ctNone: ;
  ctInteger     : begin
                  end;
  ctDouble      : ;
  ctCurrency: ;
  ctTimeLog: ;
  ctDateTime: ;
  ctDate: ;
  ctTime: ;
  ctBoolean: ;
  ctEnums: ;
  ctSet: ;
  ctString: ;
  ctMultiline: ;
  ctName: ;
  ctPassword: ;
  ctPhone: ;
  ctEmail: ;
  ctAddress: ;
  ctCity: ;
  ctCountry: ;
  ctPostalCode: ;
  ctUrl: ;
  ctFileName: ;
  ctIpv4: ;
  ctIpv6: ;
  ctTID: ;
  ctFileExt: ;
  ctMimeType: ;
  ctBlobStr: ;
  ctFile: ;
  ctCel: ;
  ctFax: ;
  ctIDnum: ;
  ctRegNo: ;
  ctVatNo: ;
  ctGPS: ;
  ct_QUERY: ;
 end;
end;

function _Field.GetValue(const aData: TVariant; const aName: String): TVariant;
begin
 Assert(False);
end;

procedure _Field.SetValue(const aData: TVariant; const aName: String; const Value: TVariant);
begin
 Assert(False);
end;

{ _FieldEnumSet }

constructor _FieldEnumSet.Create(const aName, aClassName: String; aParent: _Class; aDefaultCSV : String = ''; aCanAdd : Boolean = True );
var Itms  : TStringDynArray;
begin
 inherited Create ( aName, ctEnums,[],0);
 fItems  := TStringList.Create(dupIgnore,False,False);
 fCanAdd := True;
 Itms    := ToARR(aDefaultCSV);
 fCanAdd := aCanAdd;
end;

destructor _FieldEnumSet.Destroy;
begin
 fItems.Free;
 inherited Destroy;
end;

function _FieldEnumSet.GetItem(aIndex: Integer): String;
begin
 if aIndex>=fItems.Count
    then Result:=''
    else Result:=fItems[aIndex];
end;

function _FieldEnumSet.ToARR(aCSV: String): TStringDynArray;
var SL : TStringList;
begin
  SL:=TStringList.Create;
  SL.Delimiter:=',';
  SL.StrictDelimiter:=True;
  SL.DelimitedText:=aCSV;
  Result:= ValidateArr(SL.ToStringArray);
  SL.Free;
end;

function _FieldEnumSet.ToCSV(aItems: TStringDynArray): String;
var SL   : TStringList;
    I    : Integer;
    Itms : TStringDynArray;
begin
 Itms:=ValidateArr(aItems);
  SL:=TStringList.Create;
  SL.Delimiter:=',';
  SL.StrictDelimiter:=True;
  SL.AddStrings(Itms);
  Result:=SL.DelimitedText;
  SL.Free;
end;

function _FieldEnumSet.ValidateArr (aItems : TStringDynArray):TStringDynArray; // Will check if correct, add new ones if allowed. Return normalized array.
var I    : Integer;
begin
 Result:=[];
 for I := 0 to High(aItems) do
  begin
   if fItems.IndexOf(aItems[I])<0
      then begin
            if CanAdd
               then begin
                     fItems.Add(aItems[I]);
                     Result:=Result+[aItems[I]];
                    end
               else Assert(False,'Invalid Enum value '+aItems[I]);
           end
      else Result:=Result+[aItems[I]];
  end;
end;

{ _FieldClass }

constructor _FieldClass.Create( const aName, aClassName: String; aParent : _Class  );
begin
 inherited Create(aName,ac_OFF);
 ObjDef     := _Class.Create( aClassName, aParent );
end;

destructor _FieldClass.Destroy;
begin
 ObjDef.Free;
 inherited;
end;

{ _ObjDef }

constructor _Class.Create( const aName : String; aParent : _Class  );
begin
 Name      := aName;
 Parent    := aParent;
 MetaName  := aName;
 Version   := 1;
 fFields := TObjectList<_Field>.Create(True);
 fForms  := TObjectList<_Form >.Create(True);
end;

destructor _Class.Destroy;
begin
 fForms .Free;
 fFields.Free;
 inherited;
end;

procedure _Class.Clear;
begin
 Name        := '';
 Version     := 0;
 MetaName    := '';
 fFields.Clear;
 MetaType.Name := '';
end;

function _Class.Add( const aName: String; aAutoComplete: _AutocompleteType; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 ):_Class;
var F : _Field;
begin
 F:=_Field.Create(aName,aAutoComplete,aFlags,aMaxLen);
 fFields.Add(F);
 Result:=Self;
end;

function _Class.Add( const aName : String;  aColumnType : _ColumnType      ; aFlags : _FieldFlags = []; aMaxLen : Integer = 0 ):_Class;
var F : _Field;
begin
 F:=_Field.Create(aName,aColumnType,aFlags,aMaxLen);
 fFields.Add(F);
 Result:=Self;
end;

function _Class.AddEnum(const aName: String; aItemsCSV: String; aFlags: _FieldFlags; aCanAdd: Boolean): _Class;
var F : _Field;
begin
 F:=_FieldEnumSet.Create(aName,aName,Self,aItemsCSV,aCanAdd);
 F.MetaType.MetaMode:=mmEnum;
 fFields.Add(F);
 Result:=Self;
end;

function _Class.AddForm(const aName, aDivClass: String): _Form;
begin
 if GetForm(aName)<>nil
    then Assert(False,'Duplicate form:'+aName);
 Result:=_Form.CreateIn(nil,aName,aDivClass,'','','');
 fForms.Add(Result);
end;

function _Class.AddSet (const aName: String; aItemsCSV: String; aFlags: _FieldFlags; aCanAdd: Boolean): _Class;
var F : _Field;
begin
 F:=_FieldEnumSet.Create(aName,aName,Self,aItemsCSV,aCanAdd);
 F.MetaType.MetaMode:=mmSet;
 fFields.Add(F);
 Result:=Self;
end;

function _Class.AddList(const aName, aClassName: String): _Class;
var F : _FieldList;
begin
 F:=_FieldList.Create(aName,aClassName,Self);
 fFields.Add(F);
 Result:=F.ObjDef;
end;

function _Class.AddClass(const aName, aClassName: String): _Class;
var F : _FieldClass;
begin
 F:=_FieldClass.Create(aName,aClassName,Self);
 fFields.Add(F);
 Result:=F.ObjDef;
end;

procedure _Class.CheckDatasetFields(aDataset: TDataset);
var I : Integer;
    F : _Field;
begin
  for I := 0 to fFields.Count-1 do
    begin
    F:=fFields[I];
    if F.MetaType.FieldType=ftUnknown
        then begin
              case aDataset.FieldByName(F.Name).DataType of
              ftString  : F.MetaType.FieldType:=ftString;
              ftInteger : F.MetaType.FieldType:=ftInteger;
              ftFloat   : F.MetaType.FieldType:=ftFloat;
              ftDateTime: F.MetaType.FieldType:=ftDateTime;
              ftBoolean : F.MetaType.FieldType:=ftBoolean;
              else Assert(False,'Unknown field kind '+IntToStr(Ord(aDataset.FieldByName(F.Name).FieldKind)));
              end;
            end;
    end;
end;

procedure _Class.CreateDatasetFields(aDataset: TDataset);
var I : Integer;
    F : _Field;
begin
 for I := 0 to fFields.Count-1 do
  begin
   F:=fFields[I];
   case F.MetaType.FieldType of
    ftString  : aDataset.FieldDefs.Add(F.Name,ftString,F.MetaType.SortStrSize);
    ftInteger : aDataset.FieldDefs.Add(F.Name,ftInteger);
    ftFloat   : aDataset.FieldDefs.Add(F.Name,ftFloat);
    ftDateTime: aDataset.FieldDefs.Add(F.Name,ftDateTime);
    ftBoolean : aDataset.FieldDefs.Add(F.Name,ftBoolean);
   else Assert(False,'Unknown field type '+IntToStr(Ord(F.MetaType.FieldType)));
   end;
  end;
end;

function _Class.Done: _Class;
begin
 Result:=Parent;
end;

function _Class.GetControl(const aName: String): _Control;
begin
 Assert(False,'GetControl');
end;

function _Class.GetFieldsCSV(const aCSV: String): TArray<_Field>;
var A   : TStringDynArray;
    I   : Integer;
begin
 if (aCSV='')or(aCSV='*')
    then Result:=fFields.ToArray
    else begin
          A:=CSVStrtoArr(aCSV);
          SetLength(Result,Length(A));
          for I := 0 to High(A) do
           begin
            Result[I]:=Field[A[I]];
           end;
         end;
end;

function _Class.GetFieldsDBList: TArray<_Field>;
var I,J : Integer;
    F   : _Field;
begin
 SetLength(Result,Pred(fFields.Count));
 J:=0;
 for I := 0 to Pred(fFields.Count) do
  begin
   F:=fFields[I];
   if F.MetaType.DisplayPerc>0
      then begin
            Result[J]:=F;
            Inc(J);
           end;
  end;
 SetLength(Result,J); 
end;

function _Class.GetFldDef(const aName: String): _Field;
begin
 for Result in fFields do
  begin
   if SameText(Result.Name,aName)
      then exit;
  end;
 Result:=nil;
end;

function _Class.GetFldIdx(const aName: String): Integer;
var F : _Field;
begin
 for Result:=0 to Pred(fFields.Count) do
  begin
   if SameText(fFields[Result].Name,aName)
      then exit;
  end;
 Result:=-1;
end;

function _Class.GetForm(const aName: String): _Form;
begin
 for Result in fForms do
  begin
   if Result.Name=aName
      then exit;
  end;
 Result:=nil;
end;

{ _Obj }

constructor _Obj.Create( const aKey, aName : String; const aObjDef: _Class; aOwnsObjDef : Boolean = False );
begin
 inherited Create;
 Name       := aName;
 Key        := aKey;
 ObjDef     := aObjDef;
 Tags       := TStringList.Create(dupError,True,False);
 OwnsObjDef := aOwnsObjDef;
end;

destructor _Obj.Destroy;
begin
 Tags.Free;
 inherited;
end;

procedure _Obj.BeginUpdate;
begin
 Inc(fUpdateCount);
end;

procedure _Obj.EndUpdate;
begin
 Dec(fUpdateCount);
end;

function _Obj.TryDefaultValue( aColNo : Integer; out aValue : TValue):Boolean;
begin
 Result:=False;
 aValue:=TValue.Empty;
 if (Length(fDefaults)<=aColNo) or
    ( not(ffEmptyIsDefault in ObjDef.fFields[aColNo].Flags))
    then exit;
 if ( fDefaults[aColNo].Kind<>tkUnknown)
    then begin
          aValue:=fDefaults[aColNo];
//          TValue.Make(fDefaults[aColNo].TypeData,fDefaults[aColNo].TypeInfo,aValue);
          Result := True;
         end;
end;

function _Obj.ValueIsDefault(aColNo: Integer; aValue : String ): Boolean;
var Def,
    Dat : TValue;
    OVal : Int64;
begin
 Result:=False;
 TValue.Make<String>(aValue,Dat);
 if not TryDefaultValue(aColNo,Def)
    then exit(aValue='');
 if Dat.IsOrdinal
    then begin
          // Convert aValue to Ordinal
          if not TryStrToInt64(aValue,OVal)
             then OVal:=0;
          exit(Def.AsOrdinal=OVal);
         end;
Assert(False,'CellIsDefault');
 // TODO : Check ENum/Set/Array/NestedClass and other types.
// Assert(False,'CellIsDefault');
end;

function _Obj.CellIsDefault(aColNo, aRowNo: Integer): Boolean;
var Def,
    Dat : TValue;
begin
 Result:=False;
 Dat   :=GetCel(aColNo,aRowNo);
 if not TryDefaultValue(aColNo,Def)
    then exit;
 Assert((Dat.Kind=Def.Kind)and(Dat.TypeInfo.Name=Def.TypeInfo.Name));
 if Dat.IsOrdinal
    then exit(Def.AsOrdinal=Dat.AsOrdinal);
//    else Assert(False,'CellIsDefault');
 // TODO : Check ENum/Set/Array/NestedClass and other types.
// Assert(False,'CellIsDefault');
end;

function _Obj.ChangeCount: Integer;
begin
 Result:=Length(fDelta);
end;

procedure _Obj.Clear ( aOnlyData : Boolean = True );
begin
 fCurrentRow :=-1;
 SetLength(fRows,0);
 if not aOnlyData
    then ObjDef.Clear;
end;

function _Obj.CopyOneFromDataset(aDataset: TDataset): String;
var I : Integer;
    F : _Field;
begin
  try

// TODO : Inefficient?
//        DO NOT USE name-> FieldIdx calls; or at least absolute minimum; no loops

    aDataset.Append;
    for I := 0 to ColCount-1 do
      begin
      F:=Field[I];
      case F.MetaType.FieldType of
        ftString   : SetValue(I,TValue.From<String   >(aDataset.FieldByName(F.Name).AsString  ));
        ftInteger  : SetValue(I,TValue.From<Integer  >(aDataset.FieldByName(F.Name).AsInteger ));
        ftLargeint : SetValue(I,TValue.From<Int64    >(aDataset.FieldByName(F.Name).AsLargeInt));
        ftFloat    : SetValue(I,TValue.From<Double   >(aDataset.FieldByName(F.Name).AsFloat   ));
        ftDateTime : SetValue(I,TValue.From<TDateTime>(aDataset.FieldByName(F.Name).AsDateTime));
        ftBoolean  : SetValue(I,TValue.From<Boolean  >(aDataset.FieldByName(F.Name).AsBoolean ));
        ftBlob     : SetValue(I,TValue.From<String   >(aDataset.FieldByName(F.Name).AsString  ));
        else Assert(False,'Unknown field type '+IntTostr(Ord(F.MetaType.FieldType)));
      end;
      end;
  except
    on E: Exception do
    begin
      Result:=E.Message;
      aDataset.Cancel;
    end;
  end;
end;

function _Obj.CopyOneToDataset(aDataset: TDataset): String;
var I : Integer;
    F : _Field;
begin


 try
  aDataset.Append;
  for I := 0 to ColCount-1 do
    begin
    F:=Field[I];
    case F.MetaType.FieldType of
      ftString  : aDataset.FieldByName(F.Name).AsString := GetValue(I).AsString;
      ftInteger : aDataset.FieldByName(F.Name).AsInteger:= GetValue(I).AsInteger;
      ftFloat   : aDataset.FieldByName(F.Name).AsFloat  := GetValue(I).AsExtended;
      ftDateTime: aDataset.FieldByName(F.Name).AsDateTime:= GetValue(I).AsExtended;
      ftBoolean : aDataset.FieldByName(F.Name).AsBoolean := GetValue(I).AsBoolean;
      ftBlob    : aDataset.FieldByName(F.Name).AsString := GetValue(I).AsString;
      else Assert(False,'Unknown field type '+IntToStr(Ord(F.MetaType.FieldType)));
    end;
    end;
  aDataset.Post;
 except
   on E: Exception do
   begin
     Result:=E.Message;
     aDataset.Cancel;
   end;
 end;
end;

function _Obj.CopyAllFromDataset(aDataset: TDataset): String;
var S     : String;
    I     : Integer;
    RecNo : Integer;
begin
  Result:='';
  Clear;
  aDataset.DisableControls;
  RecNo:=aDataset.RecNo;
  try
    while not aDataset.EOF do
      begin
      S:=CopyOneFromDataset(aDataset);
      if S<>''
          then Result:=Result+'['+aDataset.RecNo.ToString.PadLeft(4,' ')+']:'+S+#13#10;
      aDataset.Next;
      end;
  finally
   aDataset.RecNo:=RecNo;
   aDataset.EnableControls;
  end;
end;

function _Obj.CopyAllToDataset( aDataset: TDataset ): String;
var I,J   : Integer;
    F     : _Field;
    S     : String;
    RowNo : Integer;
begin
 RowNo   :=GetRowNo;
 Result:='';
 aDataset.DisableControls;
 try
  for I := 0 to RowCount-1 do
    begin
     S:=CopyOneToDataset(aDataset);
     if S<>''
        then Result:=Result+'['+I.ToString.PadLeft(4,' ')+']:'+S+#13#10;
    end;
  finally
   aDataset.EnableControls;
   SetRowNo(RowNo);
  end;
end;

function _Obj.GetCel(aRowNo, aColNo: Integer): TValue;
begin
 Result:=fRows[aRowNo][aColNo];
 if ( Result.Kind=tkUnknown ) and
    ( ffEmptyIsDefault in ObjDef.fFields[aColNo].Flags) and
    ( Length(fDefaults)>aColNo ) and
    ( fDefaults[aColNo].Kind<>tkUnknown)
    then begin
          Result:=fDefaults[aColNo];
//          TValue.Make(fDefaults[aColNo].GetReferenceToRawData,fDefaults[aColNo].TypeInfo,Result);
         end;
end;

function _Obj.GetCelStr(aRowNo, aColNo: Integer): String;
var V : TValue;
begin
 V:=GetCel(aRowNo,aColNo);
 Result:=V.AsString;
end;

procedure _Obj.SetCelStr(aRowNo, aColNo: Integer; const Value: String);
var V : TValue;
begin
 if ValueIsDefault(aColNo,Value)
    then SetCel(aRowNo,aColNo,TValue.Empty)
    else begin
//          if fFields[aColNo] then

//          SetCel(aRowNo,aColNo,TValue.);
         end;
end;

procedure _Obj.SetCel(aRowNo, aColNo: Integer; const Value: TValue);
begin
 ExpandTo(aRowNo);
//
// if ( ffEmptyIsDefault in ObjDef.fFields[aColNo].Flags) and
//    ( Length(fDefaults)>aColNo ) and
//    ( fDefaults[aColNo].Kind<>tkUnknown) and
//    (( Value.Kind=tkUnknown ) or
//     ( Value=fRows[aRowNo][aColNo] ))
//        then begin
//          TValue.Make(fDefaults[aColNo].GetReferenceToRawData,fDefaults[aColNo].TypeInfo,Result);
//         end;
 if (ofRecordDelta in Flags) and not (ffNoDelta in ObjDef.fFields[aColNo].Flags)
    then begin
          // TODO : Record audit trail or when 'Post'/EditModeEnd
         end;
 fRows[aRowNo][aColNo]:=Value;
end;

function _Obj.GetColCount: Integer;
begin
 Result:=ObjDef.fFields.Count;
end;

function _Obj.GetField(aColNo: Integer): _Field;
begin
 Result:=ObjDef.fFields[aColNo];
end;

function _Obj.GetFormTemplate: String;
begin
 Result:=App.GetTemplate(ObjDef.Name+'Form');
end;

function _Obj.GetMeta(aColNo: Integer): _MetaType;
begin
 Result:=ObjDef.fFields[aColNo].MetaType;
end;

function _Obj.GetRoute: String;
begin
 Result:=name;
end;

function _Obj.GetRow(aRowNo: Integer): TValueDynArray;
begin
 Result:=fRows[aRowNo];
end;

function _Obj.GetRowCount: Integer;
begin
 Result:=Length(fRows);
end;

function _Obj.GetRowNo: Integer;
begin
 Result:=fCurrentRow;
end;

function _Obj.GetRows(const aWhere: String; const aWhereParams: array of const): TIntegerDynArray;
begin
 Assert(False,'GetRows');
end;

function _Obj.GetValue(aColNo: Integer): TValue;
begin
 Result:=fRows[fCurrentRow][aColNo];
end;

function _Obj.Query(const aSelectCSV, aWhere: String; const aWhereParams: array of const): TValueDynArrayArr;
begin
 // TODO :
 Assert(False,'Query');
end;

procedure _Obj.ExpandTo ( aRowNo : Integer);
var I,J : Integer;
begin
 I:=Length(fRows);
 if I<=(aRowNo+1)
    then begin // Expand and update new rows with proper column count
          SetLength(fRows,aRowNo+1);
          // TODO : Do we need to set the types in TValues so long or ensure they are vkUnknown?
          for J := I to High(fRows) do
           begin
            SetLength(fRows[J],ColCount);
           end;
         end;
end;

procedure _Obj.SetRow(aRowNo: Integer; const Value: TValueDynArray);
begin
 ExpandTo(aRowNo);
 fRows[aRowNo]:=Value;
end;

procedure _Obj.SetRowNo(const Value: Integer);
begin
 ExpandTo(Value);
 fCurrentRow:=Value;
end;

procedure _Obj.SetValue(aColNo: Integer; const Value: TValue);
begin
 ExpandTo(aColNo);
 fRows[fCurrentRow][aColNo]:=Value;
end;

//function _Obj.Stream( aStream: TStream; aKind : _StreamKind; aFlags: _StreamFlags; const aFields: TStringDynArray; aFilter: _FilterFunction): NativeInt;
//var I,J,
//    Cnt : Integer;
//    A   : TArray<Integer>;
//begin
// // Calc fields to process
// if Length(aFields)=0
//    then begin
//          // Copy all fields
//          SetLength(A,ColCount);
//          for I := 0 to Pred(ColCount) do
//           A[I]:=I;
//         end
//    else begin
//          SetLength(A,Length(aFields));
//          J:=0;
//          for I := 0 to High(aFields) do
//           begin
//            A[J]:=ObjDef.GetFldIdx(aFields[I]);
//            Inc(J);
//           end;
//         end;
//
// for I := 0 to Pred(RowCount) do
//  begin
//   if Assigned(aFilter)
//      then begin
//            if not aFilter(Self,aFlags,[])
//               then continue;
//           end;
//   case aKind of
//    skBinary  : begin
//                 for J := 0 to High(A) do
//                  begin
////                   ObjDef.fFields[A[0]].Stream(aStream,aKind,aFlags)
//                  end;
//
//                end;
//    skCSV     : ;
//    skJson    : ;
//    skHTML    : ;
//   end;
//  end;
// Assert(False,'_Obj.Stream');
//end;
//
function _Obj.ToStreamBin(aStream: TStream): NativeInt;
var I,J,C,R   : Integer;
    T         : _MetaType;
    F         : _Field;
//    Itm       : TValueDynArray;
    V         : TValue;
    TmpInt    : Integer;
    TmpUInt32 : UInt32;
    TmpByte   : Byte;
    TmpInt64  : Int64;
    TmpUInt64 : UInt64;
    TmpDouble : Double;
    TmpString : String;
begin
 // Save collection header/properties
 Result:=aStream.Position; // Do Delta at end
 C:=ColCount;
 R:=RowCount;

 StringToStream( aStream, '_Obj' ); // Magic prefix
 StringToStream( aStream, Key  );
 StringToStream( aStream, Name );
 StringToStream( aStream, ObjDef.Name );
 aStream.WriteData( ObjDef.Version );
 aStream.WriteData(C); // Columns
 aStream.WriteData(R); // Rows

 // HEADER
 for J := 0 to Pred(C) do // For each field, save header
  begin
   F := Field[J];
   StringToStream( aStream, F.Name );              // Write FieldName
   StringToStream( aStream, F.MetaType.Name ); // Write MetaName
   TmpByte := Byte(F.MetaType.TypeKind);
   case V.Kind of
    tkInteger   ,
    tkFloat     ,
    tkString    :  ;
   else Assert(False,'Unsupported TypeKind');
   end;
   aStream.WriteData(TmpByte);                     // Write TypeKind

   // TODO : Optionally include other fields susch as displaywidth, visible, displayname, etc

  end;

// ROWS & COLS
 for I := 0 to Pred(R) do // For each ROW
  begin // ROW
//   Itm:=Row[I];

   // Maybe write a BitMask before each row indicating which cells are empty

   for J:=0 to Pred(C) do
    begin // ROW
     F := Field[J];
     V := Cell[I,J];    // Values

     // Save each cell
     TmpByte:=Byte(V.Kind);
     case V.Kind of
      tkUnknown   : Assert(False);
      tkInteger   : begin
                     if T.FieldType=ftInteger
                        then aStream.WriteData(V.AsInteger)
                        else begin
                              TmpInt64:=V.AsType<Int64>;
                              aStream.WriteData(TmpInt64);
                             end;
                    end;
      tkFloat     : begin
                     TmpDouble := V.AsExtended;
                     aStream.WriteData(TmpDouble);
                    end;
      tkString    : StringToStream(aStream,V.AsString);
//      tkInt64     : aStream.WriteData(V.AsInt64);
//      tkDynArray  : begin
//                     TmpInt:=V.GetReferenceToRawArrayElement()
//                     aStream.WriteData(TmpInt);
//                    end;
//      tkArray     : ;
//      tkRecord    : ;
//      tkUString: ;
//      tkClassRef: ;
//      tkPointer: ;
//      tkProcedure: ;
//      tkMRecord: ;
//      tkEnumeration: ;
//      tkSet: ;
//      tkTVariant   : ;
//      tkInterface : ;
     else Assert(False,'Unknown TypeKind : '+GetEnumName(TypeInfo(TTypeKind),Ord(T.TypeKind)))
     end;
   end;
  end;
end;


{ From Stream }

function _Obj.FromStreamBin(aStream: TStream): NativeInt;
var fObj      : String;
    I,J,C,R   : Integer;
    T         : _MetaType;
    Itm       : _Obj;
    V         : TValue;
    TmpInt    : Integer;
    TmpUInt32 : UInt32;
    TmpByte   : Byte;
    TmpInt64  : Int64;
    TmpUInt64 : UInt64;
    TmpDouble : Double;
    TmpString : String;
begin
 // Recreate the Collection, metatypes and then load the data from aStream
 Result:=aStream.Position; // Do Delta at end

 fObj    := StringFromStream( aStream );
 if fObj<>'_Obj' // Magic prefix
    then Assert(False,'Invalid _Obj stream');
 Key     := StringFromStream( aStream );
 Name    := StringFromStream( aStream );
 ObjDef  .Name:=StringFromStream( aStream );
 aStream .ReadData( ObjDef.Version );
 aStream .ReadData(C);
 aStream .ReadData(R);

 // HEADER
 for J := 0 to Pred(C) do // For each field, save header
  begin
   aStream.ReadData(TmpByte);
   case TTypeKind(TmpByte) of
    tkInteger : begin
                 aStream.ReadData(tmpInt);
                end;
   end;
   TmpString         := StringFromStream(aStream);

// TODO :  CREATE fMeta[J] from TypeName
//   T:=TMetaType.Create ( TmpString );

//   fMeta.Add(T);
  end;

// ROWS & COLS
 fCurrentRow:=-1;
 SetLength(fRows,0);
 ExpandTo(R);

 for I := 0 to Pred(R) do // For each ROW
  begin // ROW
   SetLength(fRows[I],C);

   // Set all fValues.Kind=tkUnknown?
   // Maybe write a BitMask before each row indicating which cells are empty

   for J:=0 to Pred(C) do
    begin // ROW
//     T := fMeta[J];          // Types
     V := fRows[I][J];    // Values

     // Save each cell
     TmpByte:=Byte(T.TypeKind);
     case V.Kind of
      tkUnknown   : Assert(False);
      tkInteger   : begin

                    end;
     end;
    end;
  end;

end;

{ _App }

procedure _App.CommandReceived(aSource: TObject; aCmd: TWSMsgCmds; const aData: JSValue);
begin
 case aCmd of
  cmdValidatePreLogin   : begin

                          end;
  cmdLogin              : begin

                          end;
  cmdSubmit             : begin

                          end;
  cmdUpdateCell         : begin

                          end;
 end;
end;

constructor _App.Create(AOwner: TComponent);
begin
 inherited Create(AOwner);
 fIcons       := TDictionary<String,_Icon        > .Create;
 fMetaTypes   := TObjectDictionary<String,_MetaType    > .Create([doOwnsValues]);
 fEnums       := TObjectDictionary<String,_Enum        > .Create([doOwnsValues]);
 fClasses     := TObjectDictionary<String,_Class       > .Create([doOwnsValues]);
 fCollections := TObjectDictionary<String,_Col         > .Create([doOwnsValues]);
 fRoutes      := TObjectDictionary<String,_Route       > .Create([doOwnsValues]);
// fFileService := TFileService.Create;
end;

destructor _App.Destroy;
begin
// fFileService .Free;
 fIcons       .Free;
 fRoutes      .Free;
 fCollections .Free;
 fClasses     .Free;
 fEnums       .Free;
 fMetaTypes   .Free;
 inherited Destroy;
end;

function _App.GetCollection(const aName: String): _Col;
begin
 if not fCollections.TryGetValue(aName,Result)
    then Assert(False);
end;

function _App.GetEnum(const aName: String): _Enum;
begin
 if fEnums.TryGetValue(aName,Result)
    then exit;
 Result     :=_Enum.Create(aName);
 fEnums     .Add(aName,Result);
end;

function _App.GetIcon(const aName: String): _Icon;
begin
 fIcons.TryGetValue(aName,Result);
end;

function _App.GetMetaType(const aName: String): _MetaType;
begin
 if FMetaTypes.TryGetValue(aName,Result)
    then exit;
 Assert(False,'Invalid MetaType '+aName);
// Result     :=_MetaType.Create(aName,ac_OFF,ctString);
// fMetaTypes .Add(aName,Result);
end;

function _App.GetObjDef(const aName: String): _Class;
begin
 if fClasses.TryGetValue(aName,Result)
    then exit;
 Assert(False,'Invalid ObjDef '+aName);
// Result     :=_Class.Create(aName);
// fClasses .Add(aName,Result);
end;

procedure _App.MessageReceived(aSource: TObject; const aMessage: String);
var val : JSValue;
    msg : TWSMsg;
begin
 Assert(False);
// val  := TJSJSON.parse(aMessage);//SocketData.jsobject.toString);
// Msg  := TWSMsg(val);
// datarecords.cmdValidatePreLogin
end;

function _App.OpenCollection(const aTableName: String;
  aEnsureIntegrity: Boolean): JSValue;
begin

end;

function _App.RetrieveList(const aTableName, aSelect: String;
  aWhere: _WhereFunc; aEnsureIntegrity: Boolean): JSValue;
begin

end;

procedure _App.SetCollection(const aName: String; const Value: _Col);
begin
 fCollections.AddOrSetValue(aName,Value);
end;

procedure _App.SetEnum(const aName: String; const Value: _Enum);
begin
 fEnums.AddOrSetValue(aName,Value);
end;

procedure _App.SetIcon(const aName: String; aValue:_Icon);
begin
 fIcons.AddOrSetValue(aName,aValue);
end;

procedure _App.SetMetaType(const aName: String; const Value: _MetaType);
begin
 fMetaTypes.AddOrSetValue(aName,Value);
end;

procedure _App.SetObjDef(const aName: String; const Value: _Class);
begin
 fClasses.AddOrSetValue(aName,Value);
end;

function _App.GetKeyItem(const aPath: String): TObject;
var SL : TStringList;
begin
 SL := TStringList.Create;
 SL.DelimitedText := aPath;
 Result:=GetKeyPath(SL.ToStringArray,0);
 SL.Free;
end;

function _App.GetKeyPath(const aPath: TStringDynArray; const aIndex : Integer): TObject;
begin
 if fCollections.ContainsKey(aPath[aIndex])
    then begin
          if aIndex=High(aPath)
             then exit(fCollections[aPath[aIndex]])
             else begin
                   // TODO
                   // fCollections[aPath[aIndex]].GetKeyPath(aPath,aIndex+1);
                   Assert(False);
                  end;
         end;
end;

procedure _App.AddClass( const aClass: _Class);
begin
 if fClasses.ContainsKey(aClass.Name)
    then Assert(False,'Duplicate class name : '+aClass.Name);
 fClasses.Add(aClass.Name,aClass);
end;

procedure _App.AddCollection( const aCol: _Col);
begin
 if fCollections.ContainsKey(aCol.Name)
    then Assert(False,'Duplicate collection name : '+aCol.Name);
 fCollections.Add(aCol.Name,aCol);
end;

procedure _App.AddEnum(const aEnum: _Enum);
begin
 if fEnums.ContainsKey(aEnum.Name)
    then Assert(False,'Duplicate Enum name : '+aEnum.Name);
 fEnums.Add(aEnum.Name,aEnum);
end;

procedure _App.AddMeta ( const aMeta : _MetaType );
begin
 fMetaTypes.Add(aMeta.Name,aMeta);
end;

{ _Route }

constructor _Route.Create(const aName, aPath: String);
begin
 Name   := aName;
 Params := TParams.Create;
end;

destructor _Route.Destroy;
begin
 Params.Free;
 inherited;
end;

{ _RouteCollection }

constructor _RouteCollection.Create(const aName, aPath: String;  aCollection: _Obj);
begin
 inherited Create (aName,aPath);
 Collection := aCollection;
end;

{ _Control }

function _Control.Add<T>(const aName, aType: String): T;
begin
 Result         := T.Create;
 Result.fParent := Self;
 Result.Name    := aName;
 fChildren.Add(_Control(Result));
end;

constructor _Control.Create;
begin
 inherited Create;
 fParent   := nil;
 fName     := '';
 fDisplay  := '';
 fId       := '';
 fValue    := '';
 fDivClass := '';
 fHead     := '';
 fBody     := '';
 fFoot     := '';
 fChildren := TObjectList<_Control>.Create(True);
end;

constructor _Control.CreateIn( aParent : _Control; const aName, aDivClass, aHead, aBody, aFoot : String );
begin
 inherited Create;
 fParent   := aParent;
 fName     := aName;
 fDisplay  := aName;
 fId       := aName;
 fValue    := '';
 fDivClass := aDivClass;
 fHead     := aHead;
 fBody     := aBody;
 fFoot     := aFoot;
 fChildren := TObjectList<_Control>.Create(True);
end;

destructor _Control.Destroy;
begin
 fChildren.Free;
 fParent:=nil;
 inherited Destroy;
end;

function _Control.GetHTML(aFlags: _ViewFlags; const aStyle: String): String;
begin

end;

procedure _Control.SetParent(const Value: _Control);
begin
 if Assigned(fParent)
    then begin
          Assert(False,'// TODO : Change parents');
         end;
 fParent := Value;
end;

{ TObjURLs }

constructor TObjURLs.Create(const aFields, aDataURL : String);
begin
 Fields    := CSVStrToArr(aFields);
 DataURL   := aDataURL;
end;


function TObjURLs.IsOrm: Boolean;
begin
 Result:=Pos('/',DataURL)>0;
end;

{ TBitBuffer }

function TBitBuffer.BitsAvail: NativeUInt;
begin

end;

function TBitBuffer.BitsUsed: NativeUInt;
begin

end;

function TBitBuffer.GetBit: Boolean;
begin

end;

procedure TBitBuffer.GetBits(aData: POinter; aCount: Integer);
begin

end;

procedure TBitBuffer.init(aByteSize: Integer);
begin

end;

procedure TBitBuffer.PutBit(aOn: Boolean);
begin

end;

procedure TBitBuffer.PutBits(aData: Pointer; aCount: Integer);
begin

end;

function TBitBuffer.Start: Pointer;
begin

end;

{ TServerMessage }

function TServerMessage.MsgType: TWSMsgCmds;
begin
 if message='update_cell'
    then exit(cmdUpdateCell);
 if message='table_content'
    then exit(cmdUpdateTable);
end;

{ TTableContent }

function TTableContent.GetCell(const aRow, aCol: Integer): String;
begin
 Result:=content[aRow*aCol];
end;

function TTableContent.GetSorted(aSortColNo: Integer; aCols, aRows: TIntegerDynArray): TStringDynArray;
var SL : TStringList;
    zCols,
    zRows : TIntegerDynArray;
    I,J   : Integer;
begin
 SL:=TStringList.Create;
 SL.Sorted:=True;

 if Length(aCols)=0
    then begin // All columns
          SetLength(zCols,cols);
          for I := 0 to Pred(cols) do
           zCols[I]:=I;
         end
    else zCols:=aCols;

 if Length(aRows)=0
    then begin // All columns
          SetLength(zRows,rows);
          for I := 0 to Pred(Rows) do
           zRows[I]:=I;
         end
    else zRows:=aRows;

 SetLength(Result,Length(zRows)*Length(zCols));
 for I := 0 to High(zRows) do
  begin
   for J := 0 to High(zCols) do
    begin
     Result[I*J]:=Cell[zRows[I],zCols[J]];
    end;
  end;
end;

procedure TTableContent.SetCell(const aRow, aCol: Integer; const aValue: String);
begin
 content[aRow*aCol]:=aValue;
end;

{ _Input }

constructor _Input.Create( const aName, aValue: String; aMeta : _MetaType; const aDivClass:String = 'col-12 col-md-6'; aLabelPos : _LabelPos = lpTop; aFill : String = 'currentColor'; aViewFlags : _ViewFlags = [vfVert] );
begin
 name       := aName;
 value      := aValue;
 divClass   := aDivClass;
 LabelPos   := aLabelPos;
 fill       := aFill;
 fIconSize  := 16;
end;



{ _FieldQuery }

constructor _FieldQuery.Create(const aName, aTableName: String; aQueries: _QueryArr = []; const aDefaultQuery : String ='' );
begin
 inherited Create(aName,ct_QUERY,[ffHidden,ffLookup]);
 TableName    := aTableName;
 Queries      := aQueries;
 if (aDefaultQuery='')and(Length(Queries)>0)
    then DefaultQuery :=Queries[0].Name
    else DefaultQuery := aDefaultQuery;
end;


///    DEFAULTS    ////

{ _App }

procedure _App.SetupDefaults;
begin
 // Meta types
 SetupMetaTypes;
 // ENums
 SetupEnums;
 // Classes
 SetupClasses;
 // Collections
 SetupCollections;
 // Forms/Routes
 SetupRoutes;
end;

procedure _App.SetupMetaTypes;
var M : _MetaType;
begin
 // Integer, String, Double


end;

procedure _App.SetupEnums;
var E : _Enum;
begin
 E:=_Enum.Create('PhoneTypes','Home,Work,Voice,Video,SMS,APP','',True);
 fEnums.Add(E.Name,E);
 E:=_Enum.Create('EMailTypes','Home,Work,Support,Accounts,News','',True);
 fEnums.Add(E.Name,E);
end;

procedure _App.SetupIcons;
begin
// fIcons.Add('EmailAddress','M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z');
// fIcons.Add('PhoneNumber' ,'M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z');
end;


procedure _App.SetupClasses;
var Contact  : _Class;
    P        : _Class;
    A        : _Form;
begin
 // Define TVCNameRec Class
 Contact := _Class.Create('Contact',nil).
         Add('ID'               , ctTID,[ffPrimaryKey,ffIndexed,ffRequired,ffWriteOnce,ffHidden]).
         AddEnum('Capacity'  , 'Personal,Organization',[ffRequired,ffSetValueToDefaults],False).
         AddClass('Info','TPersonalInformation').
           Add('Avatar'           , acPhoto,[ffEmptyIsDefault]).
           Add('FullName'         , acName           ,[ffRequired,ffIndexed]).
           Add('GivenName'        , acGivenName      ).
           Add('IDnumber'         , ctIDnum         ,[ffWriteOnce,ffIndexed]).
           Done.
         AddClass('Company info','TCompanyInfo').
           Add('Name'             , acOrganization     ,[ffIndexed]).
           Add('Title'            , acOrganizationTitle,[ffIndexed]).
           Add('CompanyPhone'     , ac_ON              ,[ffIndexed]).
           Add('CompanyEmail'     , ac_ON              ,[ffIndexed]).
           Add('RegNo'            , ctRegNo         ,[                     ]).// 'visible=Capacity='Company'
           Add('VatNo'            , ctVatNo         ,[                     ]).// 'visible=Capacity='Company'
           Done.
         AddList('PhoneNumbers','TPhoneNumbers').
           Add    ('Number'       , ctPhone     ,[ffRequired,ffIndexed]).
           AddEnum('Type'  ,'Home,Work' ,[ffSetValueToDefaults],False).
           AddSet ('Flags' ,'Voice,SMS,IM',[ffSetValueToDefaults],False).
           Done.
         AddList('EMailAddresses','TEmailAddresses').
           Add    ('Number'       , ctEmail,[ffRequired,ffIndexed]).
           AddEnum('Type'  ,'Home,Work',[ffSetValueToDefaults],False).
           AddSet ('Flags' ,'Home,Work',[ffSetValueToDefaults],False).
           Done.
         AddList('Addresses','TAddresses').
           Add    ('Country'      , ctCountry   ,[ffRequired,ffEmptyIsDefault]).
           Add    ('City'         , ctCity      ,[ffRequired]).
           Add    ('PostalCode'   , ctPostalCode,[ffRequired]).
           AddSet ('Flags' ,'Residential,Commercial,Complex,Flats,Farm,Township,4x4',[ffSetValueToDefaults],True).
           Add    ('GPS'          , ctGPS).
           Done.
         AddList('Attachments','TAttachments').
           Add    ('FileName'     , ctFileName   ,[ffRequired]).
           Add    ('MimeType'     , ctMimeType   ,[ffRequired]).
           Add    ('Content'      , ctFile       ,[ffRequired]).
           Add    ('Thumbnail'    , ctBlobStr    ,[ffCalculated]).
           Add    ('KeyWords'     , ctMultiline  ,[]). //  AI and/or User generated
           Add    ('MetaData'     , ctBlobStr    ,[]). /// MetaData can contain TimeStamp, Size, Resolution, Pages, Origin, AI descriptive keywords
           Done.
         Done;
 App.Classes['Contact']:=Contact;
end;

procedure _App.SetupCollections;
var C : _Col;
begin
 C:=_Col.Create('Users','Users',fClasses['User']);
 fCollections.Add(C.Name,C);

 C:=_Col.Create('Contacts','Contacts',Classes['Contact'],False);
 App.Collection['Contacts']:=C;
end;

procedure _App.SetupRoutes;
var R : _Route;
begin
 R :=_RouteCollection.Create('Users','/users',fCollections['Users']);
 fRoutes.Add(R.Name,R);
end;

initialization

// App:= _App.Create(nil);

end.

