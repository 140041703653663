unit _ObjRegisters;

interface

uses _ObjUnit, _OrmCore;

procedure RegisterSignIn;

implementation

uses _WebAppU;

procedure RegisterSignIn;
var C : _Class;
    F : _Form;
begin
// F    := _Form.Create('SignIn').
//         AddSection('bg-light py-3 py-md-5').
//           AddDiv('container').
//             AddDiv('row justify-content-lg-center').
//               AddDiv('col-12 col-lg-8').
//                 AddDiv('bg-white border rounded shadow-sm overflow-hidden','padding: 40px;').
//                   AddDiv('p-4').
//                     AddImg('img-fluid w-100','','assets/TrueAnim.gif','Logo').
//                     Done.
//                   AddDiv('text-center mb-4').
//                     AddH4('signin-text','Please sign in').
//                     Done.
//                   AddForm('','','#!').
//                     AddDiv('row gy-4 p-4').
//                       AddDiv('col-12'),
//                         AddDiv('input-group').
//                           Add<_Input>


 C    := _Class.Create('Contact',nil).
         Add('ID'               , ctTID,[ffPrimaryKey,ffIndexed,ffRequired,ffWriteOnce,ffHidden]).
         AddEnum('Capacity'  , 'Personal,Organization',[ffRequired,ffSetValueToDefaults],False).
         AddClass('Info','TPersonalInformation').
           Add('Avatar'           , acPhoto,[ffEmptyIsDefault]).
           Add('FullName'         , acName           ,[ffRequired,ffIndexed]).
           Add('GivenName'        , acGivenName      ).
           Add('IDnumber'         , ctIDnum         ,[ffWriteOnce,ffIndexed]).
           Done.
         AddClass('Company info','TCompanyInfo').
           Add('Name'             , acOrganization     ,[ffIndexed]).
           Add('Title'            , acOrganizationTitle,[ffIndexed]).
           Add('CompanyPhone'     , ac_ON              ,[ffIndexed]).
           Add('CompanyEmail'     , ac_ON              ,[ffIndexed]).
           Add('RegNo'            , ctRegNo         ,[                     ]).// 'visible=Capacity='Company'
           Add('VatNo'            , ctVatNo         ,[                     ]).// 'visible=Capacity='Company'
           Done.
         AddList('PhoneNumbers','TPhoneNumbers').
           Add    ('Number'       , ctPhone     ,[ffRequired,ffIndexed]).
           AddEnum('Type'  ,'Home,Work' ,[ffSetValueToDefaults],False).
           AddSet ('Flags' ,'Voice,SMS,IM',[ffSetValueToDefaults],False).
           Done.
         AddList('EMailAddresses','TEmailAddresses').
           Add    ('Number'       , ctEmail,[ffRequired,ffIndexed]).
           AddEnum('Type'  ,'Home,Work',[ffSetValueToDefaults],False).
           AddSet ('Flags' ,'Home,Work',[ffSetValueToDefaults],False).
           Done.
         AddList('Addresses','TAddresses').
           Add    ('Country'      , ctCountry   ,[ffRequired,ffEmptyIsDefault]).
           Add    ('City'         , ctCity      ,[ffRequired]).
           Add    ('PostalCode'   , ctPostalCode,[ffRequired]).
           AddSet ('Flags' ,'Residential,Commercial,Complex,Flats,Farm,Township,4x4',[ffSetValueToDefaults],True).
           Add    ('GPS'          , ctGPS).
           Done.
         AddList('Attachments','TAttachments').
           Add    ('FileName'     , ctFileName   ,[ffRequired]).
           Add    ('MimeType'     , ctMimeType   ,[ffRequired]).
           Add    ('Content'      , ctFile       ,[ffRequired]).
           Add    ('Thumbnail'    , ctBlobStr    ,[ffCalculated]).
           Add    ('KeyWords'     , ctMultiline  ,[]). //  AI and/or User generated
           Add    ('MetaData'     , ctBlobStr    ,[]). /// MetaData can contain TimeStamp, Size, Resolution, Pages, Origin, AI descriptive keywords
           Done.
         Done;
 App.Classes['Contact']:=C;


end;

end.
